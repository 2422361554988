import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useToast,
  VStack,
  FormErrorMessage,
  FormControl,
  useColorModeValue,
  Text,
  Heading,
  Checkbox,
  HStack,
  Divider,
  Flex,
  Container,
  Link,
  Image,
  Box,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { login } from "../api";
import SocialLogin from "./SocialLogin";

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSignUpOpen: () => void;
  onLoginSuccess: () => Promise<void>;  // 추가된 prop
}

interface IForm {
  email: string;
  password: string;
}

export default function LoginModal({ isOpen, onClose, onSignUpOpen, onLoginSuccess }: LoginModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    watch,
  } = useForm<IForm>();

  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const buttonBg = "blue.300";
  const buttonHoverBg = "blue.400";
  const linkColor = "gray.500";
  const focusBorderColor = "blue.300";
  const logoSrc = useColorModeValue("/images/logo-light.png", "/images/logo-dark.png");

  const mutation = useMutation(login, {
    onSuccess: async (data) => {
      toast({
        status: "success",
        title: "다시 오신 것을 환영합니다!",
        description: "로그인되었습니다.",
        position: "bottom-right",
        duration: 3000,
        isClosable: true,
      });
      
      queryClient.setQueryData(["me"], data);
      await queryClient.refetchQueries({ queryKey: ['me', 'userProfile'], exact: true });
      
      await onLoginSuccess();  // 추가된 부분

      setTimeout(() => {
        onClose();
        reset();
        setStep(1);
      }, 1000);
    },
    onError: (error: any) => {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            setError("password", {
              type: "manual",
              message: "비밀번호를 확인해주세요.",
            });
            break;
          case 404:
            setError("email", {
              type: "manual",
              message: "등록되지 않은 이메일입니다.",
            });
            break;
          case 500:
            toast({
              status: "error",
              title: "서버 오류",
              description: "잠시 후 다시 시도해주세요.",
              position: "bottom-right",
            });
            break;
          default:
            toast({
              status: "error",
              title: "로그인 실패",
              description: "이메일 또는 비밀번호를 확인해주세요.",
              position: "bottom-right",
            });
        }
      } else {
        toast({
          status: "error",
          title: "로그인 실패",
          description: "로그인에 실패했습니다. 다시 시도해 주세요.",
          position: "bottom-right",
        });
      }
    },
  });

  const onSubmit = ({ email, password }: IForm) => {
    if (step === 1) {
      setStep(2);
    } else {
      mutation.mutate({ email, password });
    }
  };

  const handleForgotPassword = useCallback(() => {
    onClose();
    navigate("/reset-password");
  }, [onClose, navigate]);

  const handleEditEmail = useCallback(() => {
    setStep(1);
  }, []);

  const handleSignUpClick = useCallback(() => {
    onClose();
    setTimeout(() => {
      onSignUpOpen();
    }, 100);
  }, [onClose, onSignUpOpen]);

  const handleLinkClick = useCallback((path: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    onClose();
    setTimeout(() => {
      navigate(path);
    }, 100);
  }, [onClose, navigate]);

  useEffect(() => {
    if (!isOpen) {
      reset();
      setStep(1);
    }
  }, [isOpen, reset]);

  if (!isOpen) return null;

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="md">
      <ModalOverlay />
      <ModalContent
        bg={bgColor}
        color={textColor}
        borderRadius="xl"
        boxShadow="xl"
        mx={{ base: 4, md: 0 }}
      >
        <ModalBody py={8} px={8}>
          <Box textAlign="center" mb={6}>
            <Image 
              src={logoSrc}
              alt="Logo" 
              maxWidth="120px"
              mx="auto"
            />
          </Box>
          <Heading as="h2" size="md" textAlign="center" mt={10} mb={10}>
            다시 오신 것을 환영합니다
          </Heading>
          <Container maxW="xs">
            <VStack spacing={6} align="stretch">
              <form onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={4}>
                  {step === 1 ? (
                    <FormControl isInvalid={Boolean(errors.email)}>
                      <Input
                        {...register("email", {
                          required: "이메일을 입력해주세요",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "올바른 이메일 주소를 입력해주세요",
                          },
                        })}
                        placeholder="이메일 주소"
                        size="sm"
                        borderRadius="md"
                        height="40px"
                        focusBorderColor={focusBorderColor}
                      />
                      <FormErrorMessage>
                        {errors.email && errors.email.message}
                      </FormErrorMessage>
                    </FormControl>
                  ) : (
                    <>
                      <HStack w="100%" justify="space-between" align="center" mb={2}>
                        <Text fontSize="sm">{watch("email")}</Text>
                        <Button
                          size="xs"
                          variant="link"
                          onClick={handleEditEmail}
                          color={buttonBg}
                        >
                          수정
                        </Button>
                      </HStack>
                      <FormControl isInvalid={Boolean(errors.password)}>
                        <Input
                          {...register("password", {
                            required: "비밀번호를 입력해주세요",
                          })}
                          type={showPassword ? "text" : "password"}
                          placeholder="비밀번호"
                          size="sm"
                          height="40px"
                          borderRadius="md"
                          focusBorderColor={focusBorderColor}
                        />
                        <FormErrorMessage>
                          {errors.password && errors.password.message}
                        </FormErrorMessage>
                      </FormControl>
                      <Checkbox 
                        onChange={(e) => setShowPassword(e.target.checked)}
                        colorScheme="blue"
                        size="sm"
                        alignSelf="flex-start"
                      >
                        비밀번호 표시
                      </Checkbox>
                    </>
                  )}

                  <Button
                    isLoading={mutation.isLoading}
                    w="100%"
                    bg={buttonBg}
                    color="white"
                    _hover={{ bg: buttonHoverBg }}
                    size="sm"
                    borderRadius="md"
                    type="submit"
                    fontWeight="bold"
                    height="40px"
                    mt={2}
                  >
                    {step === 1 ? "계속" : "로그인"}
                  </Button>
                </VStack>
              </form>

              <VStack spacing={2} align="center" fontSize="xs" width="100%">
                <Text color="gray.500" textAlign="center">
                  계정이 없으신가요?{" "}
                  <Text
                    as="span"
                    color="blue.500"
                    cursor="pointer"
                    onClick={handleSignUpClick}
                    _hover={{ textDecoration: "underline" }}
                  >
                    회원가입
                  </Text>
                </Text>
                <Text color="gray.500" textAlign="center">
                  비밀번호를 잊으셨나요?{" "}
                  <Text
                    as="span"
                    color="blue.500"
                    cursor="pointer"
                    onClick={handleForgotPassword}
                    _hover={{ textDecoration: "underline" }}
                  >
                    비밀번호 찾기
                  </Text>
                </Text>
              </VStack>

              <Flex align="center" my={4}>
                <Divider flex={1} />
                <Text px={3} color="gray.500" fontSize="sm">또는</Text>
                <Divider flex={1} />
              </Flex>

              <SocialLogin onClose={onClose} />

              <HStack justify="center" spacing={4} mt={4}>
                <Link
                  href="/terms-of-use"
                  onClick={handleLinkClick("/terms-of-use")}
                  fontSize="xs"
                  color={linkColor}
                  _hover={{ textDecoration: "underline" }}
                >
                  이용약관
                </Link>
                <Link
                  href="/privacy-policy"
                  onClick={handleLinkClick("/privacy-policy")}
                  fontSize="xs"
                  color={linkColor}
                  _hover={{ textDecoration: "underline" }}
                >
                  개인정보처리방침
                </Link>
              </HStack>

            </VStack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}