import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Text, useToast, Tooltip } from '@chakra-ui/react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { likeKeyword } from '../../api';
import useUser from '../../lib/useUser';

interface KeywordLikeProps {
  keywordId: string;
  isLiked: boolean;
  likeCount: number;
}

export default function KeywordLike({ keywordId, isLiked, likeCount }: KeywordLikeProps) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { isLoggedIn } = useUser();

  const likeMutation = useMutation(likeKeyword, {
    onMutate: async () => {
      await queryClient.cancelQueries(['keyword', keywordId]);
      const previousData = queryClient.getQueryData(['keyword', keywordId]);
      queryClient.setQueryData(['keyword', keywordId], (old: any) => ({
        ...old,
        is_liked: !isLiked,
        likes_count: isLiked ? likeCount - 1 : likeCount + 1
      }));
      return { previousData };
    },
    onError: (err, _, context) => {
      queryClient.setQueryData(['keyword', keywordId], context?.previousData);
      toast({
        title: "오류가 발생했습니다",
        description: "잠시 후 다시 시도해주세요",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom",
        variant: "subtle",
      });
    },
    onSuccess: () => {
      toast({
        title: isLiked ? "저장을 취소했습니다" : "도서관에 저장됩니다.",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom",
        variant: "subtle",
        icon: isLiked ? <FaRegHeart /> : <FaHeart />,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['keyword', keywordId]);
    },
  });

  const handleLike = () => {
    if (isLoggedIn) {
      likeMutation.mutate(keywordId);
    } else {
      showLoginRequiredToast();
    }
  };

  const showLoginRequiredToast = () => {
    toast({
      title: "로그인이 필요합니다",
      description: "도서관에 저장할 수 있습니다.",
      status: "warning",
      duration: 4000,
      isClosable: true,
      position: "bottom",
    });
  };

  return (
    <Tooltip label={isLiked ? "취소" : "도서관"}>
      <Button
        leftIcon={isLiked ? <FaHeart /> : <FaRegHeart />}
        onClick={handleLike}
        isLoading={likeMutation.isLoading}
        size="sm"
        variant="ghost"
        color={isLiked ? "pink.500" : "gray.500"}
        _hover={{ 
          color: isLiked ? "pink.600" : "black", 
          bg: isLiked ? "pink.100" : "gray.200" 
        }}
        px={3}
      >
        <Text fontSize="sm" fontWeight="normal">
          {likeCount}
        </Text>
      </Button>
    </Tooltip>
  );
}