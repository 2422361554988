import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Container,
  UnorderedList,
  ListItem,
  OrderedList,
  useBreakpointValue,
} from '@chakra-ui/react';

export default function Terms() {
  const containerWidth = useBreakpointValue({ base: "100%", md: "90%", lg: "80%", xl: "70%" });

  // Consistent color values for both light and dark modes
  const bgColor = "gray.50";
  const containerBgColor = "white";
  const textColor = "black";
  const headingColor = "black";
  const mutedTextColor = "gray.600";

  return (
    <Box bg={bgColor} minHeight="100vh" py={[8, 12, 16]} px={[4, 6, 8]}>
      <Container maxW={containerWidth} bg={containerBgColor} p={[6, 8, 10]} borderRadius="md" boxShadow="md">
        <VStack spacing={8} align="stretch">
          <Text fontSize="sm" color={mutedTextColor} textAlign="center">
            최종 수정일: 2024년 09월 09일
          </Text>
          <Heading as="h1" size="xl" textAlign="center" color={headingColor}>
            이용약관
          </Heading>

          <Text color={textColor}>
            본 이용약관은 PVMLKOREA co.,Ltd.(이하 "회사")가 제공하는 워드저니 서비스(이하 "서비스")의 이용 조건과 절차, 회사와 회원 간의 권리와 의무 등 기본적인 사항을 규정합니다.
            본 서비스를 이용하시면 아래의 이용 약관에 동의하신 것으로 간주됩니다.
            서비스 이용 전 반드시 약관 내용을 확인해 주시기 바랍니다.
          </Text>

          <VStack spacing={6} align="stretch">
            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제1조 (목적)
              </Heading>
              <Text mt={2} color={textColor}>
                본 약관은 회사가 제공하는 AI 이미지 생성을 위한 프롬프트 키워드 사전 서비스인 워드저니의 이용과 관련하여 회사와 회원 간의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제2조 (용어의 정의)
              </Heading>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>"서비스"란 회원이 접근하여 이용할 수 있는 워드저니 관련 제반 서비스를 의미합니다.</ListItem>
                <ListItem>"회원"이란 본 약관에 동의하고 회사와 서비스 이용계약을 체결한 자를 말합니다.</ListItem>
                <ListItem>"콘텐츠"란 회사가 제공하는 프롬프트 키워드, 가이드라인, 설명 등 모든 종류의 정보를 의미합니다.</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제3조 (약관의 효력 및 변경)
              </Heading>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>본 약관은 서비스를 이용하고자 하는 모든 회원에 대해 그 효력을 발생합니다.</ListItem>
                <ListItem>회사는 합리적인 사유가 있는 경우 약관을 변경할 수 있으며, 변경 시 최소 7일 전에 공지사항을 통해 고지합니다.</ListItem>
                <ListItem>회원은 변경된 약관에 동의하지 않을 경우 회원탈퇴를 요청할 수 있으며, 변경된 약관의 효력 발생일 이후에도 서비스를 계속 이용하는 경우 약관의 변경사항에 동의한 것으로 간주됩니다.</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제4조 (서비스의 제공 및 변경)
              </Heading>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>회사는 다음과 같은 서비스를 제공합니다:
                  <UnorderedList>
                    <ListItem>AI 이미지 생성을 위한 프롬프트 키워드 사전 제공</ListItem>
                    <ListItem>프롬프트 작성 가이드라인 및 팁 제공</ListItem>
                    <ListItem>키워드, 프롬프트 "내 단어장" 저장 기능</ListItem>
                    <ListItem>회원 간 프롬프트 공유 및 평가 기능</ListItem>
                    <ListItem>예시 프롬프트에 따른 AI 이미지 제공</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 서비스의 전부 또는 일부를 변경할 수 있습니다.</ListItem>
                <ListItem>서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등을 그 변경 전 7일 이상 해당 서비스 초기화면에 게시합니다.</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제5조 (서비스 이용제한)
              </Heading>
              <Text mt={2} color={textColor}>
                회사는 회원이 다음 각 호의 사유에 해당하는 경우 서비스 이용을 제한할 수 있습니다:
              </Text>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>타인의 정보를 도용하거나 허위 정보를 입력한 경우</ListItem>
                <ListItem>회사가 정한 서비스 운영정책을 위반한 경우</ListItem>
                <ListItem>서비스의 운영을 고의로 방해한 경우</ListItem>
                <ListItem>타인의 지적재산권을 침해하거나 명예를 훼손한 경우</ListItem>
                <ListItem>기타 관련 법령에 위배되는 행위를 한 경우</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제6조 (회원의 의무)
              </Heading>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항을 준수하여야 합니다.</ListItem>
                <ListItem>회원은 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대해 회사는 책임을 지지 않습니다.</ListItem>
                <ListItem>회원은 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제7조 (회사의 의무)
              </Heading>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>회사는 특별한 사유가 없는 한 서비스 제공설비를 항상 운용 가능한 상태로 유지보수하여야 합니다.</ListItem>
                <ListItem>회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.</ListItem>
                <ListItem>회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제8조 (저작권)
              </Heading>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속됩니다.</ListItem>
                <ListItem>회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</ListItem>
                <ListItem>회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다.</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제9조 (준거법 및 재판관할)
              </Heading>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>본 약관의 해석 및 회사와 회원간의 분쟁에 대하여는 대한민국의 법을 적용합니다.</ListItem>
                <ListItem>서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 민사소송법상의 관할법원에 제기합니다.</ListItem>
              </OrderedList>
            </Box>
          </VStack>

          <Text fontWeight="bold" mt={6} color={textColor}>
            본 이용약관은 2024년 9월 9일부터 시행됩니다.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};