import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  Box,
  Input,
  Text,
  Button,
  HStack,
  Flex,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Switch,
  Spinner,
  VStack,
  Image as ChakraImage,
  useColorModeValue,
  Container,
  useBreakpointValue,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { getKeywords, logSearch } from "../api";
import { IKeyword } from "../types";

const MAX_RECENT_SEARCHES = 10;
const LOGO_URL = "/images/logo-search.png";

interface SearchesProps {
  onSearch: (keyword: IKeyword) => void;
}

export default function Searches({ onSearch }: SearchesProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [recentSearches, setRecentSearches] = useState<IKeyword[]>([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isSearchHistoryEnabled, setIsSearchHistoryEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("gray.200", "gray.300");
  const hoverBgColor = useColorModeValue("gray.100", "gray.700");

  const showSmallWindowButton = useBreakpointValue({ base: false, md: true });

  const { data: keywords } = useQuery<IKeyword[]>(["keywords"], getKeywords, {
    onError: (error) => console.error("Failed to fetch keywords:", error)
  });

  const filteredKeywords = useMemo(() => {
    return keywords?.filter(keyword =>
      keyword.english_term.toLowerCase().includes(searchTerm.toLowerCase()) ||
      keyword.korean_term.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [keywords, searchTerm]);

  useEffect(() => {
    const savedSearches = localStorage.getItem('recentSearches');
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
    const savedSearchHistoryPreference = localStorage.getItem('isSearchHistoryEnabled');
    if (savedSearchHistoryPreference !== null) {
      setIsSearchHistoryEnabled(JSON.parse(savedSearchHistoryPreference));
    }
  }, []);

  const saveRecentSearch = useCallback((keyword: IKeyword) => {
    if (isSearchHistoryEnabled) {
      const updatedSearches = [keyword, ...recentSearches.filter(item => item.pk !== keyword.pk)].slice(0, MAX_RECENT_SEARCHES);
      setRecentSearches(updatedSearches);
      localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
    }
  }, [isSearchHistoryEnabled, recentSearches]);

  const handleSearch = useCallback(async (keyword: IKeyword) => {
    if (keyword) {
      setIsLoading(true);
      try {
        saveRecentSearch(keyword);
        await logSearch(keyword.english_term);
        setSearchTerm(keyword.english_term);
        onSearch(keyword);
      } finally {
        setIsLoading(false);
      }
    }
  }, [onSearch, saveRecentSearch]);

  const handleSearchSubmit = useCallback(async () => {
    if (!searchTerm.trim()) {
      alert("검색어를 입력해주세요.");
      return;
    }

    setIsLoading(true);

    try {
      let selectedKeyword: IKeyword | undefined;

      if (filteredKeywords && filteredKeywords.length > 0) {
        selectedKeyword = filteredKeywords[0];
      } else if (keywords) {
        selectedKeyword = keywords.reduce((closest, current) => {
          if (current.english_term.toLowerCase().startsWith(searchTerm.toLowerCase())) {
            return current;
          }
          return closest;
        }, keywords[0]);
      }

      if (selectedKeyword) {
        await handleSearch(selectedKeyword);
      } else {
        if (keywords && keywords.length > 0) {
          await handleSearch(keywords[0]);
        } else {
          alert("검색 결과가 없습니다.");
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, filteredKeywords, keywords, handleSearch]);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleSearchClick = useCallback((keyword: IKeyword) => {
    handleSearch(keyword);
  }, [handleSearch]);

  const handleRemoveRecentSearch = useCallback((pk: number) => {
    const updatedSearches = recentSearches.filter(item => item.pk !== pk);
    setRecentSearches(updatedSearches);
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
  }, [recentSearches]);

  const handleClearAllRecentSearches = useCallback(() => {
    setRecentSearches([]);
    localStorage.removeItem('recentSearches');
  }, []);

  const toggleSearchHistory = useCallback(() => {
    const newState = !isSearchHistoryEnabled;
    setIsSearchHistoryEnabled(newState);
    localStorage.setItem('isSearchHistoryEnabled', JSON.stringify(newState));
    if (!newState) {
      handleClearAllRecentSearches();
    }
  }, [isSearchHistoryEnabled, handleClearAllRecentSearches]);

  const openSmallWindow = useCallback(() => {
    window.open('/small-window', 'SmallWindow', 'width=400,height=600,resizable=yes,scrollbars=yes');
  }, []);

  return (
    <Container maxW={{ base: "95%", xl: "80%" }} px={4}>
      <VStack
        spacing={1}
        width="100%"
        maxW="400px"
        mx="auto"
        bg={bgColor}
        color={textColor}
      >
        <Flex justifyContent="flex-end" width="100%" alignItems="center">
          {showSmallWindowButton && (
            <Button
              variant="ghost"
              fontSize={{ base: "xs", md: "sm" }}
              rightIcon={<Icon as={FaExternalLinkAlt} />}
              onClick={openSmallWindow}
              _hover={{ bg: hoverBgColor }}
              transition="all 0.2s"
            >
              작은창
            </Button>
          )}
        </Flex>

        <Box width="100%" position="relative">
          <InputGroup size="md">
            <InputLeftElement
              pointerEvents="none"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              pl={2}
              ml={2}
            >
              <ChakraImage
                src={LOGO_URL}
                alt="Logo"
                width="30px"
                height="30px"
                mr={2}
              />
            </InputLeftElement>
            <Input
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearchSubmit();
                }
              }}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setTimeout(() => setIsInputFocused(false), 200)}
              borderColor={isInputFocused ? "blue.500" : borderColor}
              borderWidth={isInputFocused ? "2px" : "1px"}
              _hover={{ borderColor: "blue.300" }}
              _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce" }}
              height="50px"
              fontSize={{ base: "md", sm: "lg" }}
              pl="60px"
              isDisabled={isLoading}
              bg={bgColor}
              color={textColor}
              borderRadius="full"
            />
            <InputRightElement height="50px">
              {isLoading ? (
                <Spinner size="sm" color="blue.500" />
              ) : (
                <IconButton
                  aria-label="Search"
                  icon={<SearchIcon />}
                  onClick={handleSearchSubmit}
                  size="xl"
                  variant="ghost"
                  color={textColor}
                  _hover={{ bg: "transparent", color: "gray.500" }}
                  _active={{ bg: "transparent", color: "gray.700" }}
                  isDisabled={isLoading}
                  mr={5}
                />
              )}
            </InputRightElement>
          </InputGroup>

          {isInputFocused && (
            <Box
              position="absolute"
              top="100%"
              left={0}
              right={0}
              bg={bgColor}
              borderRadius="md"
              boxShadow="md"
              zIndex={1}
              mt={2}
              p={2}
              borderWidth="1px"
              borderColor={borderColor}
            >
              {searchTerm && filteredKeywords && filteredKeywords.length > 0 ? (
                filteredKeywords.slice(0, MAX_RECENT_SEARCHES).map((keyword) => (
                  <Flex
                    key={keyword.pk}
                    align="center"
                    cursor="pointer"
                    onClick={() => handleSearchClick(keyword)}
                    _hover={{ bg: hoverBgColor }}
                    p={2}
                  >
                    <Icon as={SearchIcon} mr={2} color="gray.500" />
                    <Text>{keyword.english_term} ({keyword.korean_term})</Text>
                  </Flex>
                ))
              ) : (
                <>
                  <Flex justify="space-between" align="center" mb={2}>
                    <Text fontSize="xs" fontWeight="bold" color={textColor}>최근검색어</Text>
                    <HStack>
                      <Text fontSize="xs" color={textColor}>{isSearchHistoryEnabled ? "켜짐" : "꺼짐"}</Text>
                      <Switch
                        size="sm"
                        isChecked={isSearchHistoryEnabled}
                        onChange={toggleSearchHistory}
                      />
                    </HStack>
                  </Flex>
                  {isSearchHistoryEnabled ? (
                    <>
                      {recentSearches.map((keyword, index) => (
                        <Flex key={index} justify="space-between" align="center" p={2} _hover={{ bg: hoverBgColor }}>

                          <Flex align="center" cursor="pointer" onClick={() => handleSearchClick(keyword)}>
                            <Icon as={SearchIcon} mr={2} color="gray.500" />
                            <Text>{keyword.english_term} ({keyword.korean_term})</Text>
                          </Flex>

                          <IconButton
                            aria-label="Remove search"
                            icon={<CloseIcon />}
                            size="xs"
                            variant="ghost"
                            color="gray.500"
                            onClick={() => handleRemoveRecentSearch(keyword.pk)}
                            minWidth="auto"
                            height="auto"
                            padding="2px"
                            fontSize="8px"
                          />
                        </Flex>
                      ))}
                      {recentSearches.length > 0 && (
                        <Button size="sm" onClick={handleClearAllRecentSearches} mt={2} width="100%">
                          전체 삭제
                        </Button>
                      )}
                    </>
                  ) : (
                    <Text fontSize="sm" color={textColor} textAlign="center">
                      검색 기록이 꺼져 있습니다.
                    </Text>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      </VStack>
    </Container>
  );
}