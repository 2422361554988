import Cookie from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { formatDate } from "./lib/utils";
import { IKeyword, IRelatedKeyword, ICategory, IPhoto } from './types';

// JWT 토큰을 저장할 키
const TOKEN_KEY = 'jwtToken';

// axios 인스턴스 생성
const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/v1/"
      : "https://backend.wordjourney.co.kr/api/v1/",
  withCredentials: true,
});

// 토큰 관리 함수들
// 토큰 설정
export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

// 토큰 가져오기
export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

// 토큰 제거
export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  delete instance.defaults.headers.common['Authorization'];
};

// 저장된 토큰이 있다면 설정
const token = getToken();
if (token) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// 인터셉터 추가: 401 에러 처리
instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      removeToken();
      // 여기에 로그인 페이지로 리다이렉트하는 로직을 추가할 수 있습니다.
    }
    return Promise.reject(error);
  }
);

// 로그아웃
export const logOut = () =>
  instance
    .post(`users/log-out`, null, {
      headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => {
      removeToken();
      return response.data;
    });

// 카카오 로그인
export const kakaoLogin = (code: string) =>
  instance
    .post(
      `users/kakao`,
      { code },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => {
      if (response.data.token) {
        setToken(response.data.token);
      }
      return response.data;
    });

// 구글 로그인
export const googleLogin = (code: string) =>
  instance
    .post(
      `users/google`,
      { code },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => {
      if (response.data.token) {
        setToken(response.data.token);
      }
      return response.data;
    });

// 일반 로그인 인터페이스
export interface ILoginVariables {
  email: string;
  password: string;
}

// 일반 로그인
export const login = ({
  email,
  password,
}: ILoginVariables) =>
  instance.post(
    `users/log-in`,
    { email, password },
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  )
  .then((response) => {
    if (response.status === 200 && response.data.token) {
      setToken(response.data.token);
      return response.data;
    } else {
      throw new Error(response.data.error || "Login failed");
    }
  })
  .catch((error) => {
    console.error("Login error:", error);
    throw error;
  });

// 회원가입 인터페이스
export interface ISignUpVariables {
  email: string;
  password: string;
  re_password: string;
}

// 회원가입
export const signUp = ({
  email,
  password,
  re_password,
}: ISignUpVariables) => {
  console.log('Attempting to sign up:', { email, password: password.length, re_password: re_password.length });
  return instance.post(
    `users/sign-up`,
    { email, password, re_password },
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  )
  .then((response) => {
    console.log('Sign up successful:', response.data);
    if (response.data.token) {
      setToken(response.data.token);
    }
    return response.data;
  })
  .catch((error) => {
    console.error('Sign up failed:', error.response?.data || error.message);
    throw error;
  });
};

// 이메일 중복 체크
export const checkEmailExists = async (email: string): Promise<boolean> => {
  try {
    const response = await instance.post("users/check-email", { email });
    return response.data.exists;
  } catch (error) {
    console.error("Error checking email:", error);
    throw error;
  }
};

// 사용자 정보 가져오기
export const getMe = () =>
  instance.get(`users/me`)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        removeToken();
        throw new Error('Authentication failed. Please log in again.');
      }
      throw error;
    });

// 프로필 데이터 인터페이스
interface ProfileData {
  nickname?: string;
  name?: string;
  birth_date?: string;
  gender?: string;
  bio?: string;
  avatar?: string;
}

// 내 프로필 정보 가져오기
export const getMyProfile = () => 
  instance.get('users/my-profile')
    .then(response => response.data)
    .catch(error => {
      if (error.response && error.response.status === 401) {
        removeToken();
        throw new Error('Authentication failed. Please log in again.');
      }
      throw error;
    });

// 프로필 정보 업데이트
export const updateProfile = (profileData: ProfileData) => 
  instance.put('users/my-profile', profileData, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
      "Content-Type": "application/json",
    },
  }).then(response => response.data);

// 비밀번호 변경 인터페이스
export interface IChangePwVariables {
  old_password: string;
  new_password: string;
  re_new_password: string;
}

// 비밀번호 변경
export const changePW = (variables: IChangePwVariables) =>
  instance
  .put(`users/change-password`, variables, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  })
  .then((response) => response.data);  

// 비밀번호 재설정 요청
export const requestPasswordReset = (email: string) =>
  instance
    .post(
      `users/password-reset`,
      { email },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

// 비밀번호 재설정 확인 인터페이스
export interface IResetPasswordConfirmVariables {
  uid: string;
  token: string;
  new_password: string;
}

// 비밀번호 재설정 확인
export const resetPasswordConfirm = ({
  uid,
  token,
  new_password,
}: IResetPasswordConfirmVariables) =>
  instance
    .post(
      `users/password-reset/confirm`,
      { uid, token, new_password },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);  

// 위시리스트 가져오기
export const getWishlists = () =>
  instance.get(`wishlists/`).then((response) => response.data);

// 키워드 가져오기
export const getKeyword = (keywordId: string): Promise<IKeyword> =>
  instance.get(`keywords/${keywordId}/`).then((response) => response.data);

// 키워드 목록 가져오기
export const getKeywords = (): Promise<IKeyword[]> =>
  instance.get<IKeyword[]>(`keywords/`).then((response) => response.data);

// 키워드 업로드 인터페이스
export interface IUploadKeywordVariables {
  english_term: string;
  korean_term: string;
  category: number;
  description: string;
  example: string;
}

// 키워드 업로드
export const uploadKeyword = (variables: IUploadKeywordVariables) =>
  instance
    .post(`keywords/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

// 키워드 수정 인터페이스
export interface IEditKeywordVariables extends IUploadKeywordVariables {
  keywordPk: string;
}

// 키워드 수정
export const editKeyword = (variables: IEditKeywordVariables) =>
  instance
    .put(`keywords/${variables.keywordPk}/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

// 키워드 삭제 인터페이스
export interface IDeleteKeywordVariables {
  keywordPk: string;
}

// 키워드 삭제
export const deleteKeyword = (variables: IDeleteKeywordVariables) =>
  instance
    .delete(`keywords/${variables.keywordPk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

// 좋아요 응답 인터페이스
interface LikeResponse {
  liked: boolean;
  likes_count: number;
}

// 키워드 좋아요
export const likeKeyword = (keywordId: string): Promise<{ liked: boolean; likes_count: number }> =>
  instance
    .post(`keywords/${keywordId}/like/`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

// 카테고리 가져오기
export const getCategories = (): Promise<ICategory[]> => {
  return instance.get('categories/').then((response) => response.data);
};

// 좋아요한 키워드 가져오기
export const getLikedKeywords = (page: number, pageSize: number, categoryId: number | null): Promise<{ results: IKeyword[], count: number }> => {
  let url = `keywords/user/liked/?page=${page}&page_size=${pageSize}`;
  if (categoryId !== null) {
    url += `&category=${categoryId}`;
  }
  return instance.get(url).then((response) => response.data);
};

// 관련 키워드 가져오기
export const getRelatedKeywords = (keywordId: string): Promise<IRelatedKeyword[]> =>
  instance.get(`keywords/${keywordId}/related/`).then((response) => response.data);

// 관련 키워드 추가 인터페이스
export interface IAddRelatedKeywordVariables {
  keywordPk: string;
  relatedKeywordPk: string;
}

// 관련 키워드 추가
export const addRelatedKeyword = (variables: IAddRelatedKeywordVariables) =>
  instance
    .post(`keywords/${variables.keywordPk}/related`, { related: variables.relatedKeywordPk }, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

{/*업로드 포토*/} 
export const getUploadURL = () =>
  instance.post(
    `medias/photos/get-url`,
    null,
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  ).then((response) => response.data);

{/*클라우드플레어*/} 
export interface IUploadImageVarialbes {
  file: FileList;
  uploadURL: string;
}
export const uploadImage = ({ file, uploadURL }: IUploadImageVarialbes) => {
  const form = new FormData();
  form.append("file", file[0]);
  return axios
    .post(uploadURL, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

// 키워드 사진 업데이트 인터페이스
export interface IUpdateKeywordPhotoVariables {
  keywordPk: string;
  file: string;
  description: string;
}

// 키워드 관련 사진들 가져오기
export const getKeywordPhotos = async (keywordId: string): Promise<IPhoto[]> => {
  const response = await instance.get(`keywords/${keywordId}/photos`);
  return response.data;
};

// 키워드 사진 업데이트
export const updateKeywordPhoto = ({ keywordPk, file, description }: IUpdateKeywordPhotoVariables) =>
  instance
    .post(`keywords/${keywordPk}/photos`, { file, description }, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

// 예시 사진 업데이트 인터페이스
export interface IUpdateExamplePhotoVariables {
  examplePk: string;
  file: string;
  description: string;
}

//예시 사진들 가져오기
export const getExamplePhotos = async (exampleId: string): Promise<IPhoto[]> => {
  const response = await instance.get(`keywords/examples/${exampleId}/photos`);
  return response.data;
};

// 예시 사진 업데이트
export const updateExamplePhoto = ({ examplePk, file, description }: IUpdateExamplePhotoVariables) =>
  instance
    .post(`keywords/examples/${examplePk}/photos`, { file, description }, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

// 갤러리 사진 응답 인터페이스 정의
interface IGalleryPhotosResponse {
  results: IPhoto[];
  total_pages: number;
  current_page: number;
  total_count: number;
  new_fetched_ids: string[];
}

// 갤러리 사진들 가져오기 (키워드와 예시를 모두 처리)
export const getGalleryPhotos = async (
  keywordPk?: string,
  examplePk?: string,
  page: number = 1,
  fetchedIds: string[] = []
): Promise<IGalleryPhotosResponse> => {
  const url = 'keywords/gallery-photos';
  const params: { [key: string]: string | number | string[] } = { page };
  
  if (keywordPk) {
    params.keyword_pk = keywordPk;
  } else if (examplePk) {
    params.example_pk = examplePk;
  }

  if (fetchedIds.length > 0) {
    params['fetched_ids[]'] = fetchedIds;
  }

  const response = await instance.get<IGalleryPhotosResponse>(url, { params });
  return response.data;
};


// 검색어 로그 인터페이스
export interface ILogSearchVariables {
  search_term: string;
}

// 검색어 로그 함수
export const logSearch = async (search_term: string) => {
  try {
    await instance.post('analytics/log-search/', { search_term }, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    });
  } catch (error) {
    console.error('Failed to log search:', error);
  }
};

// 인기 검색어 인터페이스
export interface ITopSearch {
  search_term: string;
  count: number;
  isNew: boolean;
  change: 'up' | 'down' | 'same' | 'new';
}

// 인기 검색어 가져오기
export const getTopSearches = async (limit: number = 10): Promise<ITopSearch[]> => {
  try {
    const response = await instance.get<ITopSearch[]>(`analytics/top-searches/?limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch top searches:', error);
    throw error;
  }
};

// 피드백 데이터 인터페이스
interface FeedbackData {
  feedback_type: 'good' | 'bad';
  content?: string;
  reason?: string;
}

// 키워드 피드백 제출
export const submitKeywordFeedback = (keywordId: string, feedbackData: FeedbackData) =>
  instance.post(`feedbacks/keywords/${keywordId}/feedback/`, feedbackData, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  }).then((response) => response.data);

// 예시 피드백 제출
export const submitExampleFeedback = (exampleId: string, feedbackData: FeedbackData) =>
  instance.post(`feedbacks/examples/${exampleId}/feedback/`, feedbackData, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  }).then((response) => response.data);