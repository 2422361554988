import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Badge,
  Divider,
  Flex,
  IconButton,
  Tooltip,
  useToast,
  Image,
  Grid,
  GridItem,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { FaShare, FaArrowLeft, FaRegClipboard, FaUpload, FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa';
import { getKeyword, getKeywordPhotos, getExamplePhotos } from '../../api';
import { IKeyword, IExample, IPhoto } from '../../types';
import KeywordRelated from './KeywordRelated';
import KeywordLike from './KeywordLike';
import KeywordDetailSkeleton from '../../components/KeywordDetailSkeleton';
import useUser from '../../lib/useUser';
import FeedbackModal from '../../components/FeedBackModal';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { motion, AnimatePresence } from 'framer-motion';

export default function KeywordDetail() {
  const { keywordId } = useParams<{ keywordId: string }>();
  const toast = useToast();
  const navigate = useNavigate();
  const textColor = useColorModeValue('gray.800', 'white');
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const koreanTermColor = useColorModeValue('gray.600', 'white');
  const exampleCountColor = useColorModeValue('gray.500', 'white');
  
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState<'good' | 'bad'>('good');
  const [feedbackTarget, setFeedbackTarget] = useState<{ id: string, type: 'keyword' | 'example' }>({ id: '', type: 'keyword' });

  const location = useLocation();
  const exampleId = (location.state as { exampleId?: number })?.exampleId;

  useEffect(() => {
    // 갤러리에서 온 경우 (state와 exampleId가 있는 경우)
    if (location.state && exampleId) {
      const timer = setTimeout(() => {
        const exampleElement = document.getElementById(`example-${exampleId}`);
        if (exampleElement) {
          const yOffset = -150;
          const y = exampleElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
      return () => clearTimeout(timer);
    } 
    // 다른 페이지에서 온 경우 - 즉시 맨 위로 스크롤
    else {
      window.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, [exampleId, location.state]);

  const { data: keyword, isLoading: isKeywordLoading, refetch } = useQuery<IKeyword>(
    ['keyword', keywordId],
    () => getKeyword(keywordId || '')
  );
  
  const { data: keywordPhotos, isLoading: isKeywordPhotosLoading } = useQuery<IPhoto[]>(
    ['keywordPhotos', keywordId],
    () => getKeywordPhotos(keywordId || ''),
    { enabled: !!keywordId }
  );
  
  const exampleIds = keyword?.examples?.map(example => example.pk.toString()) || [];
  
  const { data: examplePhotos, isLoading: isExamplePhotosLoading } = useQuery<IPhoto[]>(
    ['examplePhotos', ...exampleIds],
    () => Promise.all(exampleIds.map(id => getExamplePhotos(id))).then(results => results.flat()),
    { enabled: exampleIds.length > 0 }
  );
  
  const { isLoggedIn, user } = useUser();

  const getConsistentRandomColor = (categoryName: string): string => {
    const colors: string[] = ['red', 'orange', 'yellow', 'green', 'teal', 'blue', 'cyan', 'purple', 'pink'];
    const index = categoryName.split('').reduce((acc: number, char: string) => acc + char.charCodeAt(0), 0) % colors.length;
    return colors[index];
  };

  const baseColor = getConsistentRandomColor(keyword?.category?.name ?? 'default');
  const badgeColor = useColorModeValue(`${baseColor}.100`, `${baseColor}.500`);
  const badgeTextColor = useColorModeValue(`${baseColor}.800`, "white");

  if (isKeywordLoading || isKeywordPhotosLoading) {
    return <KeywordDetailSkeleton />;
  }

  if (!keyword) {
    return <Text>Keyword not found.</Text>;
  }

  const keywordPhoto = keywordPhotos?.find(photo => photo.keyword === parseInt(keywordId || ''));

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    showToast("링크가 복사되었습니다", "친구들과 공유해보세요!");
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCopyEnglishTerm = () => {
    if (isLoggedIn) {
      navigator.clipboard.writeText(keyword.english_term);
      showToast("단어가 복사되었습니다", "프롬프트에 사용해보세요!");
    } else {
      showLoginRequiredToast();
    }
  };

  const handleCopyExample = (exampleContent: string) => {
    if (isLoggedIn) {
      navigator.clipboard.writeText(exampleContent);
      showToast("프롬프트가 복사되었습니다", "바로 사용해보세요!");
    } else {
      showLoginRequiredToast();
    }
  };

  const showToast = (title: string, description: string) => {
    toast({
      title,
      description,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "bottom",
      variant: "subtle",
      icon: <FaRegClipboard />,
    });
  };

  const showLoginRequiredToast = () => {
    toast({
      title: "로그인이 필요합니다",
      description: "무료회원가입을 하시면 다양한 혜택을 받아 볼 수 있습니다.",
      status: "warning",
      duration: 4000,
      isClosable: true,
      position: "bottom",
    });
  };

  const onKeywordClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/keywords/${keywordId}/photos`);
  };

  const handleUploadExamplePhoto = (exampleId: number) => {
    navigate(`/keywords/${keywordId}/examples/${exampleId}/photos`);
  };

  const handleFeedback = (type: 'good' | 'bad', targetId: string, targetType: 'keyword' | 'example') => {
    if (isLoggedIn) {
      setFeedbackType(type);
      setFeedbackTarget({ id: targetId, type: targetType });
      setIsFeedbackModalOpen(true);
    } else {
      showLoginRequiredToast();
    }
  };

  return (
    <Box width="100%" px={{ base: 6, sm: 8, md: 12, lg: 16 }}>
      <Container maxW="container.xl" py={8} mb={16}>
        <VStack align="stretch" spacing={8} mb={16}>
          <Box position="relative" left={{ base: -4, sm: -6, md: -8, lg: -16 }}>
            <IconButton
              aria-label="Go Back"
              icon={<FaArrowLeft />}
              onClick={handleGoBack}
              variant="ghost"
              size="sm"
            />
          </Box>

          <Box mb={4}>
            <Box display="inline-block" position="relative">
              <Box
                position="absolute"
                bottom="2px"
                left="0"
                width="100%"
                height="8px"
                bg="yellow.300"
                opacity={0.5}
                zIndex={0}
              />
              <Heading as="h3" size="md" display="inline-block" position="relative" zIndex={1}>
                키워드/단어
              </Heading>
            </Box>
          </Box>

          <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={8}>
            <GridItem>
              <Box>
                
                <Badge 
                  bg={badgeColor}
                  color={badgeTextColor}
                  mb={2}
                >
                  {keyword.category.name}
                </Badge>

                <Flex alignItems="center" flexWrap="wrap">
                  <Heading as="h1" size={{ base: "md", sm: "lg", md: "xl" }}>{keyword.english_term}</Heading>

                  <Button
                    leftIcon={<FaRegClipboard />}
                    onClick={handleCopyEnglishTerm}
                    size="sm"
                    variant="ghost"
                    color="gray.600"
                    ml={1}
                    mt={1}
                    _hover={{ color: "black", bg: "gray.100" }}
                  >
                    복사
                  </Button>

                  {user?.is_staff && (
                    <Tooltip label="사진 업로드" placement="top">
                      <IconButton
                        aria-label="Upload Photo"
                        icon={<FaUpload />}
                        onClick={onKeywordClick}
                        size="xs"
                        variant="outline"
                        colorScheme="red"
                        mt={1}
                        ml={2}
                      />
                    </Tooltip>
                  )}
                </Flex>
                
                <Heading as="h2" size={{ base: "sm", sm: "md", md: "lg" }} color={koreanTermColor} my={4}>{keyword.korean_term}</Heading>

                <Box mr={{ base: 0, sm: 8, md: 12 }}>
                <Text 
                  fontSize={{ base: "sm", sm: "md", md: "lg" }}
                  lineHeight="tall"
                  whiteSpace="pre-wrap"
                  >
                  {keyword.description}
                </Text>
                </Box>

              </Box>
            </GridItem>

            <GridItem>
              <AnimatePresence>
                {keywordPhoto && (
                  <Flex justifyContent="center" width="100%">
                  <PhotoProvider>
                    <PhotoView src={keywordPhoto.file}>
                      <Image
                        src={keywordPhoto.file}
                        alt=""
                        objectFit="contain" // 이미지 전체가 보이도록 설정
                        width="auto"        // 가로 길이를 자동으로 조정
                        maxH={{ base: "300px", md: "400px" }} // 최대 높이 제한
                        cursor="pointer"
                        borderRadius="xl"
                      />
                    </PhotoView>
                  </PhotoProvider>
                </Flex>
                )}
              </AnimatePresence>
            </GridItem>
          </Grid>

          <Flex justifyContent="flex-start" alignItems="center">
            <Flex
              bg={bgColor}
              borderRadius="xl" 
              p={2}
              display="inline-flex"
              width="fit-content"
              alignItems="center"
            >
              <KeywordLike
                keywordId={keyword.pk.toString()}
                isLiked={keyword.is_liked}
                likeCount={keyword.likes_count}
              />

              <Button
                leftIcon={<FaShare />}
                onClick={handleShare}
                size="sm"
                variant="ghost"
                color="gray"
                _hover={{ color: 'black', bg: 'gray.200' }}
              >
                공유
              </Button>

              <IconButton
                aria-label="Positive Feedback"
                icon={<FaRegThumbsUp />}
                onClick={() => handleFeedback('good', keywordId || '', 'keyword')}
                variant="ghost"
                size="sm"
                color="gray"
                _hover={{ color: 'black', bg: 'gray.200' }}
              />
           
              <IconButton
                aria-label="Negative Feedback"
                icon={<FaRegThumbsDown />}
                onClick={() => handleFeedback('bad', keywordId || '', 'keyword')}
                variant="ghost"
                size="sm"
                color="gray"
                _hover={{ color: 'black', bg: 'gray.200' }}
              />
            </Flex>
          </Flex>

          <Divider />

          {keyword.examples && keyword.examples.length > 0 && (
          <>
          <Box>
          <Box mb={4}>
              <Box display="inline-block" position="relative">
              <Box
                  position="absolute"
                  bottom="2px"
                  left="0"
                  width="100%"
                  height="8px"
                  bg="yellow.300"
                  opacity={0.5}
                  zIndex={0} 
                />
                <span style={{ position: 'relative', zIndex: 1 }}>
                  <Heading as="h3" size="md" display="inline">
                    프롬프트 예시
                  </Heading>
                  <Text as="span" ml={2} color={exampleCountColor} fontSize="sm" fontWeight="bold">
                    {keyword.examples.length}건
                  </Text>
                </span>
              </Box>
            </Box>

        <Grid templateColumns={{ base: "1fr", md: "1fr" }} gap={6}>
          {keyword.examples.map((example: IExample) => {
            const examplePhoto = examplePhotos?.find(photo => photo.example === example.pk);

            return (
              <GridItem key={example.pk} id={`example-${example.pk}`}>
                    <Box
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      bg="transparent"
                    >
                      <Flex
                        p={{ base: 4, sm: 5, md: 6 }}
                        flexDirection={{ base: "column", sm: "row" }}
                        alignItems={{ base: "stretch", sm: "flex-start" }}
                      >
                        <Box flex="1" mr={{ base: 0, sm: 4 }}>
                          <Text 
                            fontWeight="bold" 
                            fontSize={{ base: "sm", sm: "md", md: "lg" }} 
                            color={textColor}
                          >
                            {example.content}
                          </Text>
                        </Box>
                        
                        {examplePhoto && (
                          <Box flexShrink={0} width={{ base: "100%", sm: "120px" }} height={{ base: "200px", sm: "120px" }}>
                            <PhotoProvider>
                              <PhotoView src={examplePhoto.file}>
                                <Image
                                  src={examplePhoto.file}
                                  alt="Example"
                                  objectFit="cover"
                                  borderRadius="md"
                                  width="100%"
                                  height="100%"
                                  cursor="pointer"
                                  mt={3}
                                />
                              </PhotoView>
                            </PhotoProvider>
                          </Box>
                        )}
                      </Flex>

                      <Flex 
                        justifyContent="space-between" 
                        alignItems="center" 
                        bg={bgColor}
                        mt={3}
                        p={2}
                      >
                        <Box />
                        <Flex>
                          <Button
                            leftIcon={<FaRegClipboard />}
                            onClick={() => handleCopyExample(example.content)}
                            size="sm"
                            variant="ghost"
                            color="gray"
                            _hover={{ color: 'black', bg: 'gray.200' }}
                          >
                            복사
                          </Button>

                          <IconButton
                            aria-label="Positive Feedback"
                            icon={<FaRegThumbsUp />}
                            onClick={() => handleFeedback('good', example.pk.toString(), 'example')}
                            size="sm"
                            variant="ghost"
                            color="gray"
                            _hover={{ color: 'black', bg: 'gray.200' }}
                                />
                        
                          <IconButton
                            aria-label="Negative Feedback"
                            icon={<FaRegThumbsDown />}
                            onClick={() => handleFeedback('bad', example.pk.toString(), 'example')}
                            size="sm"
                            variant="ghost"
                            color="gray"
                            _hover={{ color: 'black', bg: 'gray.200' }}
                                />

                          {user?.is_staff && (
                            <Tooltip label="사진 업로드" placement="top">
                              <IconButton
                                aria-label="Upload Example Photo"
                                icon={<FaUpload />}
                                onClick={() => handleUploadExamplePhoto(example.pk)}
                                size="sm"
                                variant="ghost"
                                color="red.500"
                                _hover={{ color: 'black', bg: 'gray.200' }}
                              />
                            </Tooltip>
                          )}
                        </Flex>
                      </Flex>
                    </Box>
                  </GridItem>
                );
              })}
            </Grid>
          </Box>

          <Divider />
          </>
        )}    

        <KeywordRelated keywordId={keyword.pk.toString()} />

        </VStack>
      </Container>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
        feedbackType={feedbackType}
        targetId={feedbackTarget.id}
        targetType={feedbackTarget.type}
      />

    </Box>
  );
}