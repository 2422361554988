import React from 'react';
import { Box, VStack, Text, Badge, useColorModeValue } from "@chakra-ui/react";
import { IKeyword } from "../../types";

interface KeywordCardProps {
  keyword: IKeyword;
  onClick: () => void;
}

const KeywordCard: React.FC<KeywordCardProps> = ({ keyword, onClick }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.500");
  const textColor = useColorModeValue("gray.800", "white");
  const descriptionColor = useColorModeValue("gray.600", "gray.300");

  const getConsistentRandomColor = (categoryName: string): string => {
    const colors: string[] = ['red', 'orange', 'yellow', 'green', 'teal', 'blue', 'cyan', 'purple', 'pink'];
    const index = categoryName.split('').reduce((acc: number, char: string) => acc + char.charCodeAt(0), 0) % colors.length;
    return colors[index];
  };

  const baseColor = getConsistentRandomColor(keyword.category.name);
  const badgeColor = useColorModeValue(`${baseColor}.100`, `${baseColor}.500`);
  const badgeTextColor = useColorModeValue(`${baseColor}.800`, "white");

  return (
    <Box
      p={4}
      borderRadius="lg"
      boxShadow="md"
      bg={bgColor}
      border="1px solid"
      borderColor={borderColor}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      cursor="pointer"
      onClick={onClick}
      transition="all 0.2s"
      _hover={{
        boxShadow: "lg",
        transform: "translateY(-2px)"
      }}
    >
      <VStack align="start" spacing={2}>
        <Badge bg={badgeColor} color={badgeTextColor}>
          {typeof keyword.category === 'object' && keyword.category.name 
            ? keyword.category.name 
            : String(keyword.category)}
        </Badge>
        <Text fontSize="lg" fontWeight="bold" color={textColor}>{keyword.english_term}</Text>
        <Text fontSize="md" color={textColor}>{keyword.korean_term}</Text>
      </VStack>
      <Text mt={2} fontSize="sm" color={descriptionColor} noOfLines={2}>
        {keyword.description}
      </Text>
    </Box>
  );
};

export default KeywordCard;