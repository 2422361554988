import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  VStack, 
  Text, 
  Heading, 
  Container, 
  SimpleGrid, 
  Link,
  useBreakpointValue,
  Flex,
  Button,
  useColorModeValue,
  Badge,
  Spinner,
  Center,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getKeywords, likeKeyword } from "../../api";
import { IKeyword } from "../../types";
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import useUser from '../../lib/useUser';

const ITEMS_PER_PAGE = 9;
const MIN_LOADING_TIME = 3000; // 3초

export const CategoryDetail: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const queryClient = useQueryClient();
  const toast = useToast();
  const { isLoggedIn } = useUser();

  const { data: keywords, error, isFetching } = useQuery<IKeyword[]>(
    ["keywords"],
    getKeywords,
    {
      staleTime: 60000, // 1분
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isFetching) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, MIN_LOADING_TIME);
      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, [isFetching]);

  const containerWidth = useBreakpointValue({ base: "90%", md: "80%", lg: "70%" });
  const columns = useBreakpointValue({ base: 1, md: 2, lg: 3 });

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const likeMutation = useMutation(likeKeyword, {
    onMutate: async (keywordId) => {
      await queryClient.cancelQueries(["keywords"]);
      const previousKeywords = queryClient.getQueryData<IKeyword[]>(["keywords"]);
      if (previousKeywords) {
        const updatedKeywords = previousKeywords.map(kw =>
          kw.pk.toString() === keywordId ? { ...kw, is_liked: !kw.is_liked } : kw
        );
        queryClient.setQueryData(["keywords"], updatedKeywords);
      }
      return { previousKeywords };
    },
    onError: (err, _, context) => {
      if (context?.previousKeywords) {
        queryClient.setQueryData(["keywords"], context.previousKeywords);
      }
      toast({
        title: "오류가 발생했습니다",
        description: "잠시 후 다시 시도해주세요",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom",
        variant: "subtle",
      });
    },
    onSuccess: (_, keywordId) => {
      const keywords = queryClient.getQueryData<IKeyword[]>(["keywords"]);
      const keyword = keywords?.find(kw => kw.pk.toString() === keywordId);
      toast({
        title: keyword?.is_liked ? "도서관에 저장되었습니다." : "저장을 취소했습니다",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom",
        variant: "subtle",
        icon: keyword?.is_liked ? <FaHeart /> : <FaRegHeart />,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["keywords"]);
    },
  });

  const handleKeywordClick = (keywordId: string) => {
    navigate(`/keywords/${keywordId}`);
  };

  const handleLikeClick = (e: React.MouseEvent, keywordId: string) => {
    e.stopPropagation();  // 이벤트 버블링 방지
    if (isLoggedIn) {
      likeMutation.mutate(keywordId);
    } else {
      showLoginRequiredToast();
    }
  };

  const showLoginRequiredToast = () => {
    toast({
      title: "로그인이 필요합니다",
      description: "내 단어장에 저장할 수 있습니다.",
      status: "warning",
      duration: 4000,
      isClosable: true,
      position: "bottom",
    });
  };

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
        <Text ml={4}>Loading...</Text>
      </Center>
    );
  }

  if (error) return <Center height="100vh"><Text>단어를 불러오는 중 오류가 발생했습니다.</Text></Center>;
  if (!keywords || keywords.length === 0) return <Center height="100vh"><Text>단어가 없습니다.</Text></Center>;

  const filteredKeywords = keywords.filter(keyword => 
    typeof keyword.category === 'object' && keyword.category.name === category
  );

  if (filteredKeywords.length === 0) return <Center height="100vh"><Text>이 카테고리에 단어가 없습니다.</Text></Center>;

  const totalPages = Math.ceil(filteredKeywords.length / ITEMS_PER_PAGE);
  const paginatedKeywords = filteredKeywords.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  return (
    <Container maxW={containerWidth} py={8}>
      <Heading mb={6} textAlign="center">{category}</Heading>
      <SimpleGrid columns={columns} spacing={6}>
        {paginatedKeywords.map((keyword) => (
          <Box 
            key={keyword.pk} 
            p={5}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            bg={bgColor}
            borderColor={borderColor}
            _hover={{ shadow: 'lg', transform: 'translateY(-2px)' }}
            transition="all 0.2s"
            onClick={() => handleKeywordClick(keyword.pk.toString())}
            cursor="pointer"
          >
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <Heading fontSize="xl">
                {keyword.english_term}
              </Heading>
              <IconButton
                aria-label={keyword.is_liked ? "Unlike" : "Like"}
                icon={keyword.is_liked ? <FaHeart /> : <FaRegHeart />}
                colorScheme={keyword.is_liked ? "red" : "gray"}
                onClick={(e) => handleLikeClick(e, keyword.pk.toString())}
                isLoading={likeMutation.isLoading}
                size="sm"
                variant="ghost"
              />
            </Flex>
            <Text fontSize="md" fontWeight="bold" color="gray.500" mb={2}>
              {keyword.korean_term}
            </Text>
            <Text fontSize="sm" noOfLines={2}>
              {keyword.description}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
      <Flex justifyContent="center" mt={6}>
        <Button
          onClick={() => setPage(p => Math.max(1, p - 1))}
          isDisabled={page === 1}
          mr={2}
        >
          이전
        </Button>
        <Text alignSelf="center" mx={2}>
          {page} / {totalPages}
        </Text>
        <Button
          onClick={() => setPage(p => Math.min(totalPages, p + 1))}
          isDisabled={page === totalPages}
          ml={2}
        >
          다음
        </Button>
      </Flex>
    </Container>
  );
};