import { Heading, Spinner, Text, useToast, VStack } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { googleLogin, setToken } from "../../api";
import { debounce } from 'lodash';

export default function GoogleConfirm() {
  const { search } = useLocation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [toastShown, setToastShown] = useState(false);

  const mutation = useMutation(googleLogin, {
    onSuccess: (data) => {
      if (data.token) {
        setToken(data.token);
        queryClient.refetchQueries(["me"]);
        if (!toastShown) {
          toast({
            status: "success",
            title: "환영합니다!",
            description: "구글 로그인에 성공했습니다.",
            position: "bottom-right",
          });
          setToastShown(true);
        }
        navigate("/");
      } else {
        throw new Error("로그인 응답에 토큰이 없습니다.");
      }
    },
    onError: (error: any) => {
      console.error("구글 로그인 오류:", error);
      if (!toastShown) {
        toast({
          status: "error",
          title: "로그인 실패",
          description: error.response?.data?.error || "구글 로그인 중 오류가 발생했습니다. 다시 시도해 주세요.",
          position: "bottom-right",
        });
        setToastShown(true);
      }
      navigate("/");
    },
  });

  const confirmLogin = useCallback(() => {
    if (mutation.isLoading) return;
    const params = new URLSearchParams(search);
    const code = params.get("code");
    if (code) {
      console.log("Google auth code received:", code);
      mutation.mutate(code);
    } else {
      console.error("No Google auth code found in URL");
      if (!toastShown) {
        toast({
          status: "error",
          title: "로그인 실패",
          description: "구글 인증 코드를 받지 못했습니다.",
          position: "bottom-right",
        });
        setToastShown(true);
      }
      navigate("/");
    }
  }, [search, mutation, toast, navigate, toastShown]);

  const debouncedConfirmLogin = useCallback(
    debounce(() => {
      confirmLogin();
    }, 300),
    [confirmLogin]
  );

  useEffect(() => {
    debouncedConfirmLogin();
    return () => debouncedConfirmLogin.cancel();
  }, [debouncedConfirmLogin]);

  if (mutation.isError) {
    return (
      <VStack justifyContent="center" mt={40} spacing={4}>
        <Heading>로그인 실패</Heading>
        <Text>구글 로그인 중 오류가 발생했습니다. 다시 시도해 주세요.</Text>
        <Text>오류 내용: {mutation.error?.toString()}</Text>
      </VStack>
    );
  }

  return (
    <VStack justifyContent="center" mt={40} spacing={4}>
      <Heading>구글 로그인 처리 중...</Heading>
      <Text>잠시만 기다려 주세요.</Text>
      <Spinner size="xl" color="red.500" thickness="4px" />
    </VStack>
  );
}