import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../lib/useUser";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import LoginModal from "./LoginModal";

interface IProtectedPageProps {
  children: React.ReactNode;
}

export default function ProtectedPage({ children }: IProtectedPageProps) {
  const { isLoggedIn, userLoading } = useUser();
  const navigate = useNavigate();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(true);
  const {
    isOpen: isLoginOpen,
    onClose: onLoginClose,
    onOpen: onLoginOpen,
  } = useDisclosure();
  const {
    isOpen: isSignUpOpen,
    onClose: onSignUpClose,
    onOpen: onSignUpOpen,
  } = useDisclosure();

  useEffect(() => {
    if (!userLoading) {
      if (!isLoggedIn) {
        // 로그인이 필요한 경우의 로직
        // 예: 로그인 페이지로 리다이렉트 또는 로그인 모달 열기
        // navigate('/login');
        // 또는
        // onLoginOpen();
      }
    }
  }, [userLoading, isLoggedIn, navigate, onLoginOpen]);

  const handleLoginSuccess = async () => {
    // 로그인 성공 후 필요한 작업을 수행합니다.
    // 예를 들어, 페이지를 새로고침하거나 사용자 정보를 다시 불러올 수 있습니다.
    window.location.reload();
  };

  const handleAlertClose = () => {
    setIsOpen(false);
    navigate('/'); // 홈페이지로 리다이렉트
  };

  if (userLoading) {
    // 로딩 중일 때 표시할 내용
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent
              bg={'gray.50'}
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius="lg"
            >
              <AlertDialogHeader 
                fontSize="lg" 
                fontWeight="bold"
                color={'purple.500'}
              >
                로그인 필요
              </AlertDialogHeader>

              <AlertDialogBody>
                <Text color={'gray.600'} fontSize={'md'}>
                  이 기능을 사용하려면 로그인이 필요합니다.
                  계속하려면 로그인해 주세요.
                </Text>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleAlertClose}>
                  취소
                </Button>
                
                <Button 
                  bg={"#6B4EFF"}
                  color={"white"} 
                  onClick={() => {
                    setIsOpen(false);
                    onLoginOpen();
                  }}
                  ml={3}
                >
                  로그인
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <LoginModal 
          isOpen={isLoginOpen} 
          onClose={onLoginClose} 
          onSignUpOpen={onSignUpOpen}
          onLoginSuccess={handleLoginSuccess}
        />
      </>
    );
  }

  return <>{children}</>;
}