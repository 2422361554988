import React, { useState, useCallback, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  useToast,
  Avatar,
  Container,
  Spinner,
  Text,
  Flex,
  FormErrorMessage,
  useBreakpointValue,
  useColorModeValue,
  IconButton,
  Divider,
  Tooltip,
} from '@chakra-ui/react';
import { FiEdit2, FiSave, FiX } from 'react-icons/fi';
import { getMyProfile, updateProfile } from '../../api';

type FormData = {
  email: string;
  nickname: string;
  name: string;
  birth_date: string;
  gender: string;
  bio?: string;
  avatar: string;
};

type FieldLabels = Omit<FormData, 'avatar'>;

const fieldLabels: Record<keyof FieldLabels, string> = {
  email: '이메일',
  nickname: '닉네임',
  name: '이름',
  birth_date: '생년월일',
  gender: '성별',
  bio: '자기소개',
};

const requiredFields = ['nickname', 'name', 'birth_date', 'gender'] as const;
type RequiredField = typeof requiredFields[number];

export default function Profile() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [isEditing, setIsEditing] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<Partial<Record<RequiredField, string>>>({});
  const [formData, setFormData] = useState<FormData>({
    email: '',
    nickname: '',
    name: '',
    birth_date: '',
    gender: '',
    bio: '',
    avatar: '',
  });

  const avatarSize = useBreakpointValue({ base: 'xl', md: '2xl' });
  const headingSize = useBreakpointValue({ base: '2xl', md: '3xl' });
  const containerMaxW = useBreakpointValue({ base: '100%', md: 'container.md' });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { data: profile, isLoading, isError } = useQuery(['myProfile'], getMyProfile, {
    retry: 3,
    retryDelay: 1000,
    onError: (error) => {
      console.error("프로필 데이터를 불러오는 데 실패했습니다:", error);
      toast({
        title: "프로필 데이터를 불러오는 데 실패했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  });

  useEffect(() => {
    if (profile) {
      setFormData({
        email: profile.email || '',
        nickname: profile.nickname || '',
        name: profile.name || '',
        birth_date: profile.birth_date || '',
        gender: profile.gender || '',
        bio: profile.bio || '',
        avatar: profile.avatar || '',
      });
    }
  }, [profile]);

  const mutation = useMutation(updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['myProfile']);
      queryClient.invalidateQueries(['userProfile']);
      toast({
        title: "프로필이 업데이트되었습니다.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setIsEditing(false);
    },
    onError: () => {
      toast({
        title: "프로필 업데이트에 실패했습니다.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (requiredFields.includes(name as RequiredField)) {
      setFieldErrors(prev => ({ ...prev, [name]: value ? '' : `${fieldLabels[name as keyof FieldLabels]}은 필수입니다.` }));
    }
  }, []);

  const validateForm = useCallback((): boolean => {
    const newErrors: Partial<Record<RequiredField, string>> = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${fieldLabels[field]}은 필수입니다.`;
      }
    });
    setFieldErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleSave = useCallback(() => {
    if (!validateForm()) {
      toast({
        title: "필수 정보를 모두 입력해주세요.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const dataToSend = { ...formData };
    if (!dataToSend.bio) {
      delete dataToSend.bio;
    }
    mutation.mutate(dataToSend);
  }, [formData, mutation, validateForm]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setFormData({
      email: profile?.email || '',
      nickname: profile?.nickname || '',
      name: profile?.name || '',
      birth_date: profile?.birth_date || '',
      gender: profile?.gender || '',
      bio: profile?.bio || '',
      avatar: profile?.avatar || '',
    });
    setFieldErrors({});
  }, [profile]);

  if (isLoading) return <Box height="100vh" display="flex" alignItems="center" justifyContent="center"><Spinner size="xl" /></Box>;
  if (isError) return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      <VStack>
        <Heading size="md">프로필을 불러오는 데 실패했습니다.</Heading>
        <Button onClick={() => queryClient.invalidateQueries(['myProfile'])}>다시 시도</Button>
      </VStack>
    </Box>
  );

  return (
    <Container maxW={containerMaxW} py={10}>
      <VStack spacing={8} align="stretch">
        <Box position="relative" width="fit-content" mx="auto">
          <Avatar size={avatarSize} src={formData.avatar} name={formData.nickname} />
        </Box>
        <Heading textAlign="center" fontSize={headingSize} fontWeight="bold">
          {formData.nickname}님의 프로필
        </Heading>
        <Box 
          bg={bgColor} 
          color={textColor} 
          shadow="md" 
          rounded="xl" 
          p={6}
          borderWidth="1px"
          borderColor={borderColor}
          position="relative"
        >
          {isEditing && (
            <Tooltip label="변경 취소">
              <IconButton
                aria-label="Cancel edit"
                icon={<FiX />}
                onClick={handleCancel}
                position="absolute"
                top={2}
                right={2}
                variant="ghost"
              />
            </Tooltip>
          )}
          <VStack spacing={6} align="stretch">
            <Flex justify="space-between" align="center">
              <Text fontSize="2xl" fontWeight="semibold">
                프로필 정보
              </Text>
              {!isEditing && (
                <Button
                  onClick={handleEdit}
                  variant="ghost"
                >
                  편집
                </Button>
              )}
            </Flex>
            <Divider />
            {(Object.keys(fieldLabels) as (keyof FieldLabels)[]).map((field) => (
              <FormControl 
                key={field} 
                isInvalid={!!fieldErrors[field as RequiredField]}
                isRequired={requiredFields.includes(field as RequiredField)}
              >
                <FormLabel fontWeight="medium">{fieldLabels[field]}</FormLabel>
                {isEditing && field !== 'email' ? (
                  field === 'gender' ? (
                    <Select
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                      borderColor={fieldErrors[field as RequiredField] ? "red.300" : undefined}
                    >
                      <option value="">선택하세요</option>
                      <option value="M">남성</option>
                      <option value="F">여성</option>
                    </Select>
                  ) : field === 'bio' ? (
                    <Textarea
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                      rows={4}
                    />
                  ) : (
                    <Input
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                      type={field === 'birth_date' ? 'date' : 'text'}
                      maxLength={field === 'nickname' ? 5 : undefined}
                      borderColor={fieldErrors[field as RequiredField] ? "red.300" : undefined}
                    />
                  )
                ) : (
                  <Text fontSize="md">
                    {field === 'gender' 
                      ? formData[field] === 'M' ? '남성' : formData[field] === 'F' ? '여성' : ''
                      : formData[field] || '-'}
                  </Text>
                )}
                <FormErrorMessage>{fieldErrors[field as RequiredField]}</FormErrorMessage>
              </FormControl>
            ))}
            {isEditing && (
              <Button
                onClick={handleSave}
                alignSelf="flex-end"
                mt={4}
                size="sm"
                bg="blue.300"
                color="white"
                _hover={{ bg: "blue.400" }}
              >
                저장
              </Button>
            )}
          </VStack>
        </Box>
      </VStack>
    </Container>
  );
}