import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useToast,
  VStack,
  FormErrorMessage,
  FormControl,
  useColorModeValue,
  Text,
  Heading,
  Checkbox,
  HStack,
  Divider,
  Flex,
  Container,
  List,
  ListItem,
  ListIcon,
  Link,
  Image,
  Box,
} from "@chakra-ui/react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { signUp, checkEmailExists } from "../api";
import SocialLogin from "./SocialLogin";

interface SignUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLoginOpen: () => void;
}

interface IForm {
  email: string;
  password: string;
  re_password: string;
}

export default function SignUpModal({ isOpen, onClose, onLoginOpen }: SignUpModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    trigger,
  } = useForm<IForm>();

  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    letterAndNumber: false,
    special: false,
  });
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isPasswordEntered, setIsPasswordEntered] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const buttonBg = "blue.300";
  const buttonHoverBg = "blue.400";
  const linkColor = "gray.500";
  const focusBorderColor = "blue.300";
  const logoSrc = useColorModeValue("/images/logo-light.png", "/images/logo-dark.png");

  const mutation = useMutation(signUp, {
    onSuccess: (data) => {
      if (data.token) {
        localStorage.setItem('token', data.token);
      }
      toast({
        title: "환영합니다!",
        status: "success",
        description: "계정이 성공적으로 생성되었습니다.",
        position: "bottom-right",
        duration: 3000,
        isClosable: true,
      });
      queryClient.setQueryData(["me"], data);
      setTimeout(() => {
        onClose();
        reset();
        setStep(1);
        navigate('/');
      }, 1000);
    },
    onError: (error: any) => {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            setError("password", {
              type: "manual",
              message: "비밀번호를 확인해주세요.",
            });
            break;
          case 409:
            setError("email", {
              type: "manual",
              message: "이미 등록된 이메일입니다.",
            });
            break;
          case 500:
            toast({
              status: "error",
              title: "서버 오류",
              description: "잠시 후 다시 시도해주세요.",
              position: "bottom-right",
            });
            break;
          default:
            toast({
              status: "error",
              title: "회원가입 실패",
              description: "회원가입 중 문제가 발생했습니다. 다시 시도해 주세요.",
              position: "bottom-right",
            });
        }
      } else {
        toast({
          status: "error",
          title: "회원가입 실패",
          description: "회원가입에 실패했습니다. 다시 시도해 주세요.",
          position: "bottom-right",
        });
      }
    },
  });

  const checkEmailMutation = useMutation(checkEmailExists);

  const onSubmit = async ({ email, password, re_password }: IForm) => {
    if (step === 1) {
      const emailExists = await checkEmailMutation.mutateAsync(email);
      if (emailExists) {
        setError("email", {
          type: "manual",
          message: "이미 등록된 이메일입니다.",
        });
      } else {
        setStep(2);
      }
    } else {
      if (password !== re_password) {
        setError("re_password", {
          type: "manual",
          message: "비밀번호가 일치하지 않습니다.",
        });
        return;
      }
      if (!passwordRequirements.length || !passwordRequirements.letterAndNumber || !passwordRequirements.special) {
        setError("password", {
          type: "manual",
          message: "비밀번호는 8자 이상이며, 문자, 숫자, 특수문자를 포함해야 합니다.",
        });
        return;
      }
      mutation.mutate({ email, password, re_password });
    }
  };

  const handleEditEmail = useCallback(() => {
    setStep(1);
  }, []);

  const handleLoginClick = useCallback(() => {
    onClose();
    setTimeout(() => {
      onLoginOpen();
    }, 100);
  }, [onClose, onLoginOpen]);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setIsPasswordEntered(password.length > 0);
    setPasswordRequirements({
      length: password.length >= 8,
      letterAndNumber: /^(?=.*[A-Za-z])(?=.*\d).+$/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const handleLinkClick = useCallback((path: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    onClose();
    setTimeout(() => {
      navigate(path);
    }, 100);
  }, [onClose, navigate]);

  useEffect(() => {
    if (!isOpen) {
      reset();
      setStep(1);
    }
  }, [isOpen, reset]);

  const password = watch("password");
  const re_password = watch("re_password");

  useEffect(() => {
    if (password && re_password) {
      trigger("re_password");
    }
  }, [password, re_password, trigger]);

  if (!isOpen) return null;

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="md">
      <ModalOverlay />
      <ModalContent
        bg={bgColor}
        color={textColor}
        borderRadius="xl"
        boxShadow="xl"
        mx={{ base: 4, md: 0 }}
      >
        <ModalBody py={8} px={8}>
          <Box textAlign="center" mb={6}>
            <Image 
              src={logoSrc}
              alt="Logo" 
              maxWidth="120px"
              mx="auto"
            />
          </Box>

          <Heading as="h2" size="md" textAlign="center" mt={10} mb={10}>
            {step === 1 ? "새 계정 만들기" : "비밀번호 설정"}
          </Heading>
          <Container maxW="xs">
            <VStack spacing={6} align="stretch">
              <form onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={4}>
                  {step === 1 ? (
                    <FormControl isInvalid={Boolean(errors.email)}>
                      <Input
                        {...register("email", {
                          required: "이메일을 입력해주세요",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "올바른 이메일 주소를 입력해주세요",
                          },
                        })}
                        placeholder="이메일"
                        size="sm"
                        borderRadius="md"
                        height="40px"
                        focusBorderColor={focusBorderColor}
                      />
                      <FormErrorMessage>
                        {errors.email && errors.email.message}
                      </FormErrorMessage>
                    </FormControl>
                  ) : (
                    <>
                      <HStack w="100%" justify="space-between" align="center" mb={2}>
                        <Text fontSize="sm">{watch("email")}</Text>
                        <Button
                          size="xs"
                          variant="link"
                          onClick={handleEditEmail}
                          color={buttonBg}
                        >
                          수정
                        </Button>
                      </HStack>
                      <FormControl isInvalid={Boolean(errors.password)}>
                        <Input
                          {...register("password", {
                            required: "비밀번호를 입력해주세요",
                            onChange: handlePasswordChange,
                          })}
                          type={showPassword ? "text" : "password"}
                          placeholder="비밀번호"
                          size="sm"
                          height="40px"
                          borderRadius="md"
                          focusBorderColor={focusBorderColor}
                          onFocus={() => setIsPasswordFocused(true)}
                          onBlur={() => setIsPasswordFocused(false)}
                        />
                        <FormErrorMessage>
                          {errors.password && errors.password.message}
                        </FormErrorMessage>
                        {(isPasswordFocused || isPasswordEntered) && (
                          <List spacing={1} mt={2}>
                            <ListItem>
                              <ListIcon as={passwordRequirements.letterAndNumber ? FaCheck : FaTimes} color={passwordRequirements.letterAndNumber ? "green.500" : "red.500"} />
                              <Text as="span" fontSize="sm" color={passwordRequirements.letterAndNumber ? "green.500" : "red.500"}>
                                문자 + 숫자 포함
                              </Text>
                            </ListItem>
                            <ListItem>
                              <ListIcon as={passwordRequirements.length ? FaCheck : FaTimes} color={passwordRequirements.length ? "green.500" : "red.500"} />
                              <Text as="span" fontSize="sm" color={passwordRequirements.length ? "green.500" : "red.500"}>
                                8자 이상
                              </Text>
                            </ListItem>
                            <ListItem>
                              <ListIcon as={passwordRequirements.special ? FaCheck : FaTimes} color={passwordRequirements.special ? "green.500" : "red.500"} />
                              <Text as="span" fontSize="sm" color={passwordRequirements.special ? "green.500" : "red.500"}>
                                특수문자 포함
                              </Text>
                            </ListItem>
                          </List>
                        )}
                      </FormControl>
                      <FormControl isInvalid={Boolean(errors.re_password)}>
                        <Input
                          {...register("re_password", {
                            required: "비밀번호를 다시 입력해주세요",
                            validate: (value) =>
                              value === watch("password") || "비밀번호가 일치하지 않습니다",
                          })}
                          type={showPassword ? "text" : "password"}
                          placeholder="비밀번호 확인"
                          size="sm"
                          height="40px"
                          borderRadius="md"
                          focusBorderColor={focusBorderColor}
                        />
                        <FormErrorMessage>
                          {errors.re_password && errors.re_password.message}
                        </FormErrorMessage>
                      </FormControl>
                      <Checkbox 
                        onChange={(e) => setShowPassword(e.target.checked)}
                        colorScheme="blue"
                        size="sm"
                        alignSelf="flex-start"
                      >
                        비밀번호 표시
                      </Checkbox>
                    </>
                  )}

                  <Button
                    isLoading={mutation.isLoading}
                    w="100%"
                    bg={buttonBg}
                    color="white"
                    _hover={{ bg: buttonHoverBg }}
                    size="sm"
                    borderRadius="md"
                    type="submit"
                    fontWeight="bold"
                    height="40px"
                    mt={2}
                  >
                    {step === 1 ? "계속" : "회원가입"}
                  </Button>
                </VStack>
              </form>

              <VStack spacing={2} align="center" fontSize="xs" width="100%">
                <Text color="gray.500" textAlign="center">
                  이미 계정이 있으신가요?{" "}
                  <Text
                    as="span"
                    color="blue.500"
                    cursor="pointer"
                    onClick={handleLoginClick}
                    _hover={{ textDecoration: "underline" }}
                  >
                    로그인
                  </Text>
                </Text>
              </VStack>

              <Flex align="center" my={4}>
                <Divider flex={1} />
                <Text px={3} color="gray.500" fontSize="sm">또는</Text>
                <Divider flex={1} />
              </Flex>

              <SocialLogin onClose={onClose} />

              <HStack justify="center" spacing={4} mt={4}>
                <Link
                  href="/terms-of-use"
                  onClick={handleLinkClick("/terms-of-use")}
                  fontSize="xs"
                  color={linkColor}
                  _hover={{ textDecoration: "underline" }}
                >
                  이용약관
                </Link>
                <Link
                  href="/privacy-policy"
                  onClick={handleLinkClick("/privacy-policy")}
                  fontSize="xs"
                  color={linkColor}
                  _hover={{ textDecoration: "underline" }}
                >
                  개인정보처리방침
                </Link>
              </HStack>

            </VStack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}