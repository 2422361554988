import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
  Text,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { FiLock } from 'react-icons/fi';
import { requestPasswordReset } from '../../api';

interface IForm {
  email: string;
}

export default function ResetPw() {
  const { register, handleSubmit, formState: { errors } } = useForm<IForm>();
  const navigate = useNavigate();

  const mutation = useMutation(requestPasswordReset, {
    onSuccess: () => {
      navigate('/reset-password-done?status=success');
    },
    onError: () => {
      navigate('/reset-password-done?status=error');
    },
  });

  const onSubmit = (data: IForm) => {
    mutation.mutate(data.email);
  };

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  return (
    <Box bg={bgColor} minH="100vh" py={12}>
      <Container maxW="container.md">
        <Box bg={cardBgColor} p={8} borderRadius="xl" boxShadow="xl">
          <VStack spacing={6} align="stretch">
            <Icon as={FiLock} w={10} h={10} color="blue.300" />
            <Heading textAlign="center" fontSize={{ base: "2xl", md: "3xl" }} mb={2}>
              비밀번호 재설정
            </Heading>
            <Text textAlign="center" color="gray.500" fontSize="md">
              비밀번호를 잊어버리셨나요? 걱정하지 마세요!
            </Text>
            <Text textAlign="center" color="gray.500" fontSize="sm">
              가입하신 이메일을 입력해주세요.
              잠시 후 해당 이메일로 비밀번호 재설정 메일이 발송됩니다.
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing={4}>
                <FormControl isInvalid={!!errors.email}>
                  <FormLabel>이메일</FormLabel>
                  <Input
                    {...register('email', {
                      required: '이메일을 입력해주세요',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: '유효한 이메일 주소를 입력해주세요',
                      },
                    })}
                    placeholder="your@email.com"
                    size="lg"
                    bg={useColorModeValue('white', 'gray.700')}
                  />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
                <Button 
                  type="submit" 
                  bg="blue.300"
                  color="white" 
                  isLoading={mutation.isLoading}
                  loadingText="요청 중..."
                  size="lg"
                  width="full"
                  mt={4}
                >
                  비밀번호 재설정 링크 받기
                </Button>
              </VStack>
            </form>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
}