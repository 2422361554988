import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import Home from "./routes/Home";
import KakaoConfirm from "./routes/social/KakaoConfirm";
import GoogleConfirm from "./routes/social/GoogleConfirm";
import NotFound from "./routes/NotFound";
import KeywordDetail from "./routes/keyword/KeywordDetail";
import KeywordSave from "./routes/menu/KeywordSave";
import Profile from "./routes/menu/Profile";
import ChangePw from "./routes/menu/ChangePw";
import { CategoryDetail } from "./routes/categories/CategoryDetail";
import { SmallWindow } from "./components/SmallWindow";
import  UploadKeywordPhoto from "./routes/photo/UploadKeywordPhoto";
import UploadExamplePhoto from "./routes/photo/UploadExamplePhoto";
import ResetPw from "./routes/menu/ResetPw";
import ResetPwDone from "./routes/menu/ResetPwDone";
import ResetPwConfirm from "./routes/menu/ResetPwConfirm";
import ResetPwComplete from "./routes/menu/ResetPwComplete";
import Terms from "./routes/legal/Terms";
import Privacy from "./routes/legal/Privacy";
import Trend from "./routes/menu/Trend";
import Gallery from "./routes/menu/Gallery";



const router = createBrowserRouter([
{
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
    
      {
        path: "social",
        children: [
          {
            path: "kakao",
            element: <KakaoConfirm />,
          },
          {
            path: "google",
            element: <GoogleConfirm />,
          },
        ],
      },

      {
        path: "keywords/:keywordId",  
        element: <KeywordDetail />, 
      },

      {
        path: "keywords/:keywordId/photos",
        element: <UploadKeywordPhoto/>,
      },

      {
        path: "keywords/:keywordId/examples/:exampleId/photos",
        element: <UploadExamplePhoto />,
      },


      {
        path: "saved-keywords",  // 새로 추가한 경로
        element: <KeywordSave />,
      },

      {
        path: "my-profile",
        element: <Profile />,
      },

      {
        path: "my-profile/changepw",
        element: <ChangePw />,
      },

      {
        path: "reset-password",
        element: <ResetPw />,
      },

      {
        path: "reset-password-done",
        element: <ResetPwDone />,
      },

      {
        path: "reset-password/:uid/:token",
        element: <ResetPwConfirm />,
      },

      {
        path: "password-reset-complete",
        element: <ResetPwComplete />,
      },

      {
        path: "category/:category",
        element: <CategoryDetail />,
      },

      {
        path: "small-window",
        element: <SmallWindow />,
      },

      {
        path: "terms-of-use",
        element: <Terms />,
      },

      {
        path: "privacy-policy",
        element: <Privacy />,
      },

      {
        path: "trend",
        element: <Trend />,
      },

      {
        path: "gallery",
        element: <Gallery />,
      },

    ]
}		
]);

export default router;