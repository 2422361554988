import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  VStack,
  useToast,
  Container,
  Heading,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { getUploadURL, uploadImage, updateExamplePhoto } from "../../api";
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 150px));
  grid-gap: 16px;
  margin-top: 30px;
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #000;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    transform: scale(1.1);
  }
`;

const AddImageButton = styled.label`
  display: block;
  max-width: 300px;
  margin: 16px auto 0;
  margin-top: 50px;
  border-radius: 8px;
  border: 2px solid #000;
  padding: 16px;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: #000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
  }

  input[type='file'] {
    display: none;
  }
`;

interface IForm {
  file: FileList;
}

interface IUploadURLResponse {
  id: string;
  uploadURL: string;
}

const UploadExamplePhoto: React.FC = () => {
  const { keywordId, exampleId } = useParams<{ keywordId: string, exampleId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { register, handleSubmit, watch, reset } = useForm<IForm>();
  const toast = useToast();

  const updatePhotoMutation = useMutation(updateExamplePhoto, {
    onSuccess: () => {
      console.log('Photo update successful');
      toast({
        status: "success",
        title: "Example photo updated!",
        isClosable: true,
      });
      reset();
      queryClient.invalidateQueries(['example', exampleId]);
      navigate(`/keywords/${keywordId}`);
    },
    onError: (error: any) => {
      console.error('Update photo mutation error:', error);
      toast({
        status: "error",
        title: "Failed to update example photo",
        description: error.message || "An unknown error occurred",
        isClosable: true,
      });
    }
  });
  
  const uploadImageMutation = useMutation(uploadImage, {
    onSuccess: ({ result }: any) => {
      console.log('Image upload successful:', result);
      if (exampleId) {
        const cloudflareUrl = `https://imagedelivery.net/K7Zur3bs8cZJmSTH0ntuAQ/${result.id}/prompt`;
        updatePhotoMutation.mutate({
          examplePk: exampleId,
          file: cloudflareUrl,
          description: "Example photo",
        });
      }
    },
  });

  const uploadURLMutation = useMutation(getUploadURL, {
    onSuccess: (data: IUploadURLResponse) => {
      console.log('Got upload URL:', data.uploadURL);
      uploadImageMutation.mutate({
        uploadURL: data.uploadURL,
        file: watch("file"),
      });
    },
  });

  const onSubmit = () => {
    uploadURLMutation.mutate();
  };

  {/*멀티 사진 업로드 삭제*/}
  const [images, setImages] = useState<File[]>([]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files);
      const newImages = [...images, ...filesArray].slice(0, 20);
      const totalSize = newImages.reduce((acc, image) => acc + image.size, 0);
      if (totalSize > 500000000) {
        alert('Total file size exceeds 500MB limit');
      } else {
        setImages(newImages);
      }
    }
  };
  const handleDeleteImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  return (
    <Container>
      <Heading mt={5} textAlign={"center"}>예시프롬프트 업로드</Heading>
    
      <VStack as="form" onSubmit={handleSubmit(onSubmit)} spacing={4}>

        <FormControl>
          사진추가
          <Input {...register("file")} type="file" accept="image/*" onChange={handleFileChange}/>
        </FormControl>

        <Grid>
        {images.map((image, index) => (
          <ImageContainer key={index}>
            <img src={URL.createObjectURL(image)} alt="preview" />
            <DeleteButton onClick={() => handleDeleteImage(index)}>X</DeleteButton>
          </ImageContainer>
        ))}
      </Grid>

        <Button
          isLoading={
            updatePhotoMutation.isLoading ||
            uploadImageMutation.isLoading ||
            uploadURLMutation.isLoading
          }
          type="submit"
          colorScheme="blue"
        >
          업로드
        </Button>
      </VStack>
    </Container>
  );
};

export default UploadExamplePhoto;