import React from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Text,
  Container,
  Heading,
  Flex,
  Button,
  useColorModeValue,
  Badge,
  Center,
  Wrap,
  WrapItem,
  Tag,
  Icon,
  useBreakpointValue,
  Grid,
  GridItem,
  Skeleton,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { getLikedKeywords, getCategories } from '../../api';
import { IKeyword, ICategory } from '../../types';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IoBookOutline } from 'react-icons/io5';

const ITEMS_PER_PAGE = 10;

export default function KeywordSave() {
  const [page, setPage] = React.useState(1);
  const [selectedCategory, setSelectedCategory] = React.useState<number | null>(null);
  const [categoryCount, setCategoryCount] = React.useState<Record<number, number>>({});

  const { data: categoriesData } = useQuery<ICategory[]>(['categories'], getCategories);

  const { data, error, isFetching } = useQuery<{ results: IKeyword[], count: number }>(
    ['likedKeywords', page, selectedCategory],
    () => getLikedKeywords(page, ITEMS_PER_PAGE, selectedCategory),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  React.useEffect(() => {
    if (data) {
      const newCategoryCount = data.results.reduce((acc, keyword) => {
        acc[keyword.category.pk] = (acc[keyword.category.pk] || 0) + 1;
        return acc;
      }, {} as Record<number, number>);
      setCategoryCount(newCategoryCount);
    }
  }, [data]);

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const headingSize = useBreakpointValue({ base: '2xl', md: '3xl' });
  const containerMaxWidth = useBreakpointValue({ base: 'container.sm', md: 'container.md', lg: 'container.lg' });

  const handleCategoryChange = (categoryId: number | null) => {
    setSelectedCategory(categoryId);
    setPage(1);
  };

  if (error) return <Center height="100vh"><Text>단어를 불러오는 중 오류가 발생했습니다.</Text></Center>;

  const totalPages = data ? Math.ceil(data.count / ITEMS_PER_PAGE) : 0;
  const totalCount = data?.count ?? 0;

  return (
    <Container maxW={containerMaxWidth} py={10}>
      <Flex align="center" justify="center" mb={8}>
        <Icon as={IoBookOutline} w={8} h={8} color="blue.300" mr={3} />
        <Heading fontSize={headingSize}>도서관</Heading>
      </Flex>

      <Wrap spacing={4} justify="center" mb={6} pt={4}>
        <CategoryTag
          isSelected={selectedCategory === null}
          onClick={() => handleCategoryChange(null)}
          count={totalCount}
        >
          전체
        </CategoryTag>
        {categoriesData?.map((category) => (
          <CategoryTag
            key={category.pk}
            isSelected={selectedCategory === category.pk}
            onClick={() => handleCategoryChange(category.pk)}
            count={categoryCount[category.pk] || 0}
          >
            {category.name}
          </CategoryTag>
        ))}
      </Wrap>

      {isFetching ? (
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={6}>
          {Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => (
            <GridItem key={index}>
              <Skeleton height="200px" borderRadius="lg" />
            </GridItem>
          ))}
        </Grid>
      ) : data && data.results.length > 0 ? (
        <>
          <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={6}>
            {data.results.map((keyword) => (
              <GridItem key={keyword.pk}>
                <KeywordCard keyword={keyword} />
              </GridItem>
            ))}
          </Grid>
          <Flex justifyContent="center" mt={6}>
            <Button
              onClick={() => setPage(p => Math.max(1, p - 1))}
              isDisabled={page === 1}
              leftIcon={<FaChevronLeft />}
            >
              이전
            </Button>
            <Text alignSelf="center" mx={4} fontWeight="bold">
              {page} / {totalPages}
            </Text>
            <Button
              onClick={() => setPage(p => Math.min(totalPages, p + 1))}
              isDisabled={page === totalPages}
              rightIcon={<FaChevronRight />}
            >
              다음
            </Button>
          </Flex>
        </>
      ) : (
        <Center height="50vh">
          <Text fontSize="lg" fontWeight="medium">이 카테고리에 저장된 단어가 없습니다.</Text>
        </Center>
      )}
    </Container>
  );
}

interface CategoryTagProps {
  children: React.ReactNode;
  isSelected: boolean;
  onClick: () => void;
  count: number;
}

function CategoryTag({ children, isSelected, onClick, count }: CategoryTagProps) {
  return (
    <WrapItem>
      <Box position="relative">
      <Tag
        size="lg"
        variant={isSelected ? 'solid' : 'outline'}
        colorScheme={isSelected ? 'blue' : 'gray'}
        color={isSelected ? 'white' : 'inherit'}
        backgroundColor={isSelected ? 'blue.300' : 'transparent'}
        cursor="pointer"
        onClick={onClick}
        borderRadius="full"
        px={4}
        py={2}
        _hover={{ transform: 'translateY(-2px)', shadow: 'md' }}
        transition="all 0.2s"
      >
        {children}
      </Tag>
        {isSelected && (
          <Badge
            colorScheme="red"
            bg="red.500"
            color="white"
            borderRadius="full"
            position="absolute"
            top="-8px"
            right="-8px"
            fontSize="0.7em"
            minWidth="20px"
            height="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            boxShadow="0 0 0 2px white"
          >
            {count}
          </Badge>
        )}
      </Box>
    </WrapItem>
  );
}

interface KeywordCardProps {
  keyword: IKeyword;
}

function KeywordCard({ keyword }: KeywordCardProps) {
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const getConsistentRandomColor = (categoryName: string): string => {
    const colors: string[] = ['red', 'orange', 'yellow', 'green', 'teal', 'blue', 'cyan', 'purple', 'pink'];
    const index = categoryName.split('').reduce((acc: number, char: string) => acc + char.charCodeAt(0), 0) % colors.length;
    return colors[index];
  };

  const baseColor = getConsistentRandomColor(keyword.category.name);
  const badgeColor = useColorModeValue(`${baseColor}.100`, `${baseColor}.500`);
  const badgeTextColor = useColorModeValue(`${baseColor}.800`, "white");

  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg={bgColor}
      borderColor={borderColor}
      _hover={{ shadow: 'lg', transform: 'translateY(-4px)' }}
      transition="all 0.3s"
      as={RouterLink}
      to={`/keywords/${keyword.pk}`}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text fontSize="xl" fontWeight="bold" noOfLines={1}>
          {keyword.english_term}
        </Text>
        <Badge 
          bg={badgeColor}
          color={badgeTextColor}
          fontSize="0.5em" 
          p={1} 
          borderRadius="full"
        >
          {keyword.category.name}
        </Badge>
      </Flex>
      <Text fontSize="md" fontWeight="bold" color="blue.300" mb={2}>
        {keyword.korean_term}
      </Text>
      <Text fontSize="sm" noOfLines={3} flex={1}>
        {keyword.description}
      </Text>
    </Box>
  );
}