import React, { useRef } from 'react';
import { VStack, Button, Flex, Text, useToast, ToastId } from '@chakra-ui/react';
import { FaArrowLeft, FaUser, FaKey, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logOut } from "../api";

interface PrivateSettingsProps {
  onBack: () => void;
  onClose: () => void;
  onLogoutSuccess: () => void;
}

export default function PrivateSettings({ onBack, onClose, onLogoutSuccess }: PrivateSettingsProps) {
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toastId = useRef<ToastId>();

  const mutation = useMutation(logOut, {
    onMutate: () => {
      toastId.current = toast({
        title: "로그아웃 중...",
        description: "잠시만 기다려주세요.",
        status: "loading",
        duration: 10000,
        position: "bottom-right",
        id: toastId.current,
      });
    },
    onSuccess: () => {
      if (toastId.current) {
        queryClient.refetchQueries({ queryKey: ['me'] });
        queryClient.refetchQueries({ queryKey: ['userProfile'] });
        toast.update(toastId.current, {
          title: "로그아웃 완료!",
          description: "다음에 또 만나요 😊",
          status: "success",
          duration: 3000,
        });
      }
      onLogoutSuccess(); // Call the onLogoutSuccess function
      navigate("/"); // Navigate to home page
    },
    onError: () => {
      if (toastId.current) {
        toast.update(toastId.current, {
          title: "로그아웃 실패",
          description: "다시 시도해주세요.",
          status: "error",
          duration: 3000,
        });
      }
    },
  });

  const handleLogout = () => {
    mutation.mutate();
  };

  const handleButtonClick = (to: string) => {
    navigate(to);
    onClose();
  };

  return (
    <VStack spacing={4} align="stretch">
      <Flex alignItems="center" mb={2}>
        <Button
          leftIcon={<FaArrowLeft />}
          variant="ghost"
          onClick={onBack}
          size="sm"
          alignSelf="flex-start"
        >
          뒤로
        </Button>
      </Flex>
      <Text fontWeight="bold" fontSize="lg" ml={8}>
        개인설정
      </Text>
      
      <Button
        onClick={() => handleButtonClick('/my-profile')}
        size="md"
        leftIcon={<FaUser />}
        variant="ghost"
        justifyContent="flex-start"
      >
        프로필
      </Button>

      <Button
        onClick={() => handleButtonClick('/my-profile/changepw')}
        size="md"
        leftIcon={<FaKey />}
        variant="ghost"
        justifyContent="flex-start"
      >
        비밀번호 변경
      </Button>

      <Button
        onClick={handleLogout}
        size="md"
        leftIcon={<FaSignOutAlt />}
        isLoading={mutation.isLoading}
        variant="ghost"
        justifyContent="flex-start"
      >
        로그아웃
      </Button>
    </VStack>
  );
}