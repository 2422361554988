import React from 'react';
import { Button, VStack, Box } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { RiKakaoTalkFill } from 'react-icons/ri';

interface SocialLoginProps {
  onClose: () => void;
}

const SocialLogin: React.FC<SocialLoginProps> = ({ onClose }) => {
  const googleLogin = () => {
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const googleRedirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;

    if (!googleClientId || !googleRedirectUri) {
      console.error('Google Client ID or Redirect URI is not defined');
      return;
    }

    const googleParams = {
      client_id: googleClientId,
      redirect_uri: googleRedirectUri,
      response_type: "code",
      scope: "email profile",
    };

    const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?${new URLSearchParams(
      googleParams
    ).toString()}`;
    window.location.href = googleUrl;
  };

  const kakaoLogin = () => {
    const kakaoClientId = process.env.REACT_APP_KAKAO_CLIENT_ID;
    const kakaoRedirectUri = process.env.REACT_APP_KAKAO_REDIRECT_URI;

    if (!kakaoClientId || !kakaoRedirectUri) {
      console.error('Kakao Client ID or Redirect URI is not defined');
      return;
    }

    const kakaoParams = {
      client_id: kakaoClientId,
      redirect_uri: kakaoRedirectUri,
      response_type: "code",
    };

    const kakaoUrl = `https://kauth.kakao.com/oauth/authorize?${new URLSearchParams(
      kakaoParams
    ).toString()}`;
    window.location.href = kakaoUrl;
  };

  return (
    <Box width="100%">
      <VStack spacing={4} width="100%" align="stretch">
        <Button
          leftIcon={<FcGoogle size="20px" />}
          onClick={() => {
            onClose();
            googleLogin();
          }}
          width="100%"
          variant="outline"
          justifyContent="center"
          color="gray.700"
          fontWeight="normal"
          fontSize="sm"
          height="45px"
          bg="white"
          _hover={{ bg: 'gray.50' }}
        >
          Google 계속하기
        </Button>
        <Button
          leftIcon={<RiKakaoTalkFill size="20px" color="#3C1E1E" />}
          onClick={() => {
            onClose();
            kakaoLogin();
          }}
          width="100%"
          variant="outline"
          justifyContent="center"
          color="#3C1E1E"
          fontWeight="normal"
          fontSize="sm"
          height="45px"
          bg="#FEE500"
          _hover={{ bg: '#FDD835' }}
        >
          카카오톡 계속하기
        </Button>
      </VStack>
    </Box>
  );
};

export default SocialLogin;