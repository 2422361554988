import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getMe } from "../api";
import { IUser } from "../types";

export default function useUser() {
    const queryClient = useQueryClient();
    const { isLoading, data, isError } = useQuery<IUser>(["me"], getMe, {
        retry: false,
        refetchOnWindowFocus: false,
    });

    const mutate = () => queryClient.invalidateQueries(["me"]);

    return {
        userLoading: isLoading,
        user: data,
        isLoggedIn: !isError,
        mutate,
    };
}