import React from 'react';
import { Box, Flex, Text, useColorModeValue, Tag } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';

interface CategoryProps {
  categories: string[];
}

export const Category: React.FC<CategoryProps> = ({ categories }) => {
  const navigate = useNavigate();
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const hoverBgColor = useColorModeValue("gray.100", "gray.600");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const tagColor = useColorModeValue("blue.300", "blue.300");

  return (
    <Flex overflowX="auto" justifyContent="flex-start" width="100%">
      {categories.map((category, index) => (
        <Tag
          key={index}
          mr={3}
          mb={2}
          px={4}
          py={2}
          bg={bgColor}
          color={textColor}
          borderRadius="full"
          cursor="pointer"
          onClick={() => navigate(`/category/${category}`)}
          _hover={{ bg: hoverBgColor, transform: "translateY(-2px)" }}
          transition="all 0.2s"
          boxShadow="sm"
          fontSize="md"
          fontWeight="medium"
          flexShrink={0}
        >
          <Text>{category}</Text>
          <Box 
            as="span" 
            ml={2} 
            bg={tagColor} 
            w={2} 
            h={2} 
            borderRadius="full"
          />
        </Tag>
      ))}
    </Flex>
  );
};