import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  VStack,
  Container,
  Icon,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { FiCheckCircle, FiAlertCircle, FiArrowLeft } from 'react-icons/fi';

export default function ResetPwDone() {
  const location = useLocation();
  const status = new URLSearchParams(location.search).get('status');

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const iconColor = status === 'success' ? 'green.500' : 'red.500';

  return (
    <Box bg={bgColor} minH="100vh" py={12}>
      <Container maxW="container.sm">
        <Box bg={cardBgColor} p={8} borderRadius="xl" boxShadow="xl">
          <VStack spacing={8} align="stretch">
            <Icon
              as={status === 'success' ? FiCheckCircle : FiAlertCircle}
              w={16}
              h={16}
              color={iconColor}
              margin="auto"
            />
            <Heading textAlign="center" fontSize={{ base: "2xl", md: "3xl" }} mb={2}>
              {status === 'success' ? '이메일 전송 완료' : '오류 발생'}
            </Heading>
            <Text fontSize="lg" textAlign="center" color={useColorModeValue('gray.600', 'gray.300')}>
              {status === 'success' 
                ? '비밀번호 재설정 링크가 이메일로 전송되었습니다. 이메일을 확인해 주세요.'
                : '비밀번호 재설정 요청 중 문제가 발생했습니다. 다시 시도해 주세요.'}
            </Text>
            <Text fontSize="md" textAlign="center" color={useColorModeValue('gray.500', 'gray.400')}>
              이메일을 받지 못하셨다면, 스팸 폴더를 확인하시거나 이메일 주소를 다시 한 번 확인해 주세요.
            </Text>
            <Button 
              leftIcon={<FiArrowLeft />} 
              colorScheme={status === 'success' ? 'green' : 'red'} 
              variant="outline"
              size="lg"
              width="full"
              onClick={() => window.history.back()}
            >
              돌아가기
            </Button>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
}