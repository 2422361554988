import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Container,
  UnorderedList,
  ListItem,
  OrderedList,
  useBreakpointValue,
} from '@chakra-ui/react';

export default function Privacy() {
  const containerWidth = useBreakpointValue({ base: "100%", md: "90%", lg: "80%", xl: "70%" });

  // Consistent color values for both light and dark modes
  const bgColor = "gray.50";
  const containerBgColor = "white";
  const textColor = "black";
  const headingColor = "black";
  const mutedTextColor = "gray.600";

  return (
    <Box bg={bgColor} minHeight="100vh" py={[8, 12, 16]} px={[4, 6, 8]}>
      <Container maxW={containerWidth} bg={containerBgColor} p={[6, 8, 10]} borderRadius="md" boxShadow="md">
        <VStack spacing={8} align="stretch">
          <Text fontSize="sm" color={mutedTextColor} textAlign="center">
            최종 수정일: 2024년 09월 09일
          </Text>
          <Heading as="h1" size="xl" textAlign="center" color={headingColor}>
            개인정보처리방침
          </Heading>

          <Text color={textColor}>
            PVMLKOREA co.,Ltd.(이하 "회사")는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
          </Text>

          <VStack spacing={6} align="stretch">
            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제1조 (개인정보의 처리 목적)
              </Heading>
              <Text mt={2} color={textColor}>
                회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
              </Text>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>회원 가입 및 관리</ListItem>
                <ListItem>서비스 제공 및 개선</ListItem>
                <ListItem>신규 서비스 개발 및 맞춤 서비스 제공</ListItem>
                <ListItem>통계학적 특성에 따른 서비스 제공 및 광고 게재</ListItem>
                <ListItem>서비스의 유효성 확인</ListItem>
                <ListItem>접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제2조 (개인정보의 처리 및 보유 기간)
              </Heading>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</ListItem>
                <ListItem>각각의 개인정보 처리 및 보유 기간은 다음과 같습니다:
                  <UnorderedList>
                    <ListItem>회원 가입 및 관리 : 회원 탈퇴 시까지</ListItem>
                    <ListItem>재화 또는 서비스 제공 : 서비스 공급완료 및 요금결제·정산 완료시까지</ListItem>
                  </UnorderedList>
                </ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제3조 (개인정보의 제3자 제공)
              </Heading>
              <Text mt={2} color={textColor}>
                회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제4조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법)
              </Heading>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</ListItem>
                <ListItem>제1항에 따른 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.</ListItem>
                <ListItem>제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 "개인정보 처리 방법에 관한 고시(제2020-7호)" 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제5조 (처리하는 개인정보의 항목)
              </Heading>
              <Text mt={2} color={textColor}>
                회사는 다음의 개인정보 항목을 처리하고 있습니다.
              </Text>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>필수항목: 이메일 주소, 비밀번호, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보</ListItem>
                <ListItem>선택항목: 닉네임, 프로필 이미지</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제6조 (개인정보의 파기)
              </Heading>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</ListItem>
                <ListItem>정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</ListItem>
                <ListItem>개인정보 파기의 절차 및 방법은 다음과 같습니다:
                  <UnorderedList>
                    <ListItem>파기절차: 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</ListItem>
                    <ListItem>파기방법: 회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</ListItem>
                  </UnorderedList>
                </ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제7조 (개인정보의 안전성 확보 조치)
              </Heading>
              <Text mt={2} color={textColor}>
                회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다:
              </Text>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>관리적 조치: 내부관리계획 수립·시행, 정기적 직원 교육 등</ListItem>
                <ListItem>기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치</ListItem>
                <ListItem>물리적 조치: 전산실, 자료보관실 등의 접근통제</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제8조 (개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)
              </Heading>
              <OrderedList spacing={2} mt={2} color={textColor}>
                <ListItem>회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.</ListItem>
                <ListItem>쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</ListItem>
                <ListItem>이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</ListItem>
              </OrderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제9조 (개인정보 보호책임자)
              </Heading>
              <Text mt={2} color={textColor}>
                회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
              </Text>
              <UnorderedList spacing={2} mt={2} color={textColor}>
                <ListItem>개인정보 보호책임자
                  <UnorderedList>
                    <ListItem>성명: [이청신]</ListItem>
                    <ListItem>직책: [개발팀 이사]</ListItem>
                    <ListItem>연락처: [help.wordjourney@gmail.com]</ListItem>
                  </UnorderedList>
                </ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" color={headingColor}>
                제10조 (개인정보 처리방침 변경)
              </Heading>
              <Text mt={2} color={textColor}>
                이 개인정보처리방침은 2024년 9월 9일부터 적용됩니다. 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
              </Text>
            </Box>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};
