import React from 'react';
import { Box, Container, VStack, Grid, GridItem, Flex, Skeleton, SkeletonText } from '@chakra-ui/react';

const KeywordDetailSkeleton = () => {
  return (
    <Box width="100%" px={{ base: 6, sm: 8, md: 12, lg: 16 }}>
      <Container maxW="container.xl" py={8} mb={16}>
        <VStack align="stretch" spacing={8} mb={16}>
          {/* 뒤로가기 버튼 */}
          <Box position="relative" left={{ base: -4, sm: -6, md: -8, lg: -16 }}>
            <Skeleton width="32px" height="32px" borderRadius="md" />
          </Box>

          {/* 섹션 제목 */}
          <Box mb={4}>
            <Skeleton width="100px" height="24px" />
          </Box>

          {/* 메인 컨텐츠 그리드 */}
          <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={8}>
            {/* 왼쪽 - 텍스트 영역 */}
            <GridItem>
              <Box>
                {/* 카테고리 뱃지 */}
                <Skeleton width="80px" height="20px" mb={2} />

                {/* 영문 용어 */}
                <Flex alignItems="center" mb={4}>
                  <Skeleton width="200px" height="36px" mr={2} />
                  <Skeleton width="60px" height="32px" />
                </Flex>

                {/* 한글 용어 */}
                <Skeleton width="160px" height="30px" mb={4} />

                {/* 설명 */}
                <SkeletonText noOfLines={4} spacing={4} skeletonHeight="4" />
              </Box>
            </GridItem>

            {/* 오른쪽 - 이미지 영역 */}
            <GridItem>
              <Flex justifyContent="center" width="100%">
                <Skeleton
                  width="100%"
                  height={{ base: "300px", md: "400px" }}
                  borderRadius="xl"
                />
              </Flex>
            </GridItem>
          </Grid>

          {/* 액션 버튼 영역 */}
          <Flex>
            <Skeleton width="300px" height="40px" borderRadius="xl" />
          </Flex>

          {/* 구분선 */}
          <Skeleton height="1px" />

          {/* 예시 섹션 */}
          <Box>
            {/* 예시 제목 */}
            <Box mb={4}>
              <Skeleton width="150px" height="24px" />
            </Box>

            {/* 예시 카드 목록 */}
            <Grid templateColumns={{ base: "1fr", md: "1fr" }} gap={6}>
              {[1, 2].map((_, index) => (
                <GridItem key={index}>
                  <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                    <Flex
                      p={{ base: 4, sm: 5, md: 6 }}
                      flexDirection={{ base: "column", sm: "row" }}
                      alignItems={{ base: "stretch", sm: "flex-start" }}
                    >
                      {/* 예시 내용 */}
                      <Box flex="1" mr={{ base: 0, sm: 4 }}>
                        <SkeletonText noOfLines={3} spacing={4} skeletonHeight="4" />
                      </Box>

                      {/* 예시 이미지 */}
                      <Box 
                        flexShrink={0} 
                        width={{ base: "100%", sm: "120px" }} 
                        height={{ base: "200px", sm: "120px" }}
                      >
                        <Skeleton width="100%" height="100%" borderRadius="md" />
                      </Box>
                    </Flex>

                    {/* 예시 액션 버튼 */}
                    <Flex 
                      justifyContent="flex-end"
                      bg="gray.50"
                      mt={3}
                      p={2}
                    >
                      <Skeleton width="200px" height="32px" />
                    </Flex>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          </Box>

          {/* 연관 키워드 섹션 */}
          <Skeleton height="200px" />
        </VStack>
      </Container>
    </Box>
  );
};

export default KeywordDetailSkeleton;