import React from 'react';
import { motion } from 'framer-motion';
import { ChakraProvider, extendTheme, Box, Heading, Text, Button, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "gray.100",
      }
    }
  }
});

const MotionBox = motion(Box);

export default function NotFound() {
  return (
    <ChakraProvider theme={theme}>
      <VStack
        justifyContent="center"
        minH="100vh"
        spacing={8}
        p={4}
      >
        <MotionBox
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Heading
            as="h1"
            fontSize={{ base: "5xl", md: "7xl" }}
            fontWeight="bold"
            bgGradient="linear(to-r, teal.500, purple.500)"
            bgClip="text"
            textAlign="center"
          >
            404
          </Heading>
        </MotionBox>

        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Heading
            as="h2"
            fontSize={{ base: "2xl", md: "4xl" }}
            mb={2}
            textAlign="center"
          >
            안녕하세요, <Text as="span" color="purple.500">Wordjourney</Text> 입니다
          </Heading>
        </MotionBox>

        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <Text
            fontSize={{ base: "lg", md: "xl" }}
            textAlign="center"
            maxW="md"
          >
            죄송합니다. 요청하신 페이지를 찾을 수 없습니다.
            홈으로 돌아가 워드저니의 여정을 계속해주세요.
          </Text>
        </MotionBox>

        <MotionBox
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <Link to="/">
            <Button
              colorScheme="purple"
              size="lg"
              fontWeight="bold"
              _hover={{ bg: "teal.500" }}
              transition="all 0.3s"
            >
              홈으로 가기 →
            </Button>
          </Link>
        </MotionBox>
      </VStack>
    </ChakraProvider>
  );
}