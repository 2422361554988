import React from 'react';
import { 
  Box, 
  VStack, 
  Container,
  Heading,
  Grid, 
  GridItem, 
  Skeleton,
  useBreakpointValue,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Flex,
  Button,
  Icon
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaExternalLinkAlt } from 'react-icons/fa';

const HomeSkeleton: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });

  return (
    <Container 
      maxW={{ base: "100%", sm: "90%", md: "container.md" }} 
      centerContent 
      px={{ base: 4, sm: 6, md: 0 }}
      pb={{ base: 16, sm: 20, md: 28 }}
    >
      {/* Searches 컴포넌트 스켈레톤 */}
      <VStack spacing={4} mt={{ base: 8, sm: 14, md: 16 }} width="100%">
        <VStack spacing={4} width="100%">
          {/* '작은창' 버튼 스켈레톤 */}
          <Flex justifyContent="flex-end" width="100%" alignItems="center">
            <Skeleton>
              <Button
                variant="ghost"
                size="sm"
                rightIcon={<Icon as={FaExternalLinkAlt} />}
              >
                작은창
              </Button>
            </Skeleton>
          </Flex>

          {/* 검색 입력 필드 및 버튼 스켈레톤 */}
          <Box width="100%" position="relative">
            <InputGroup size="lg">
              <Input
                placeholder="검색..."
                height="60px"
                fontSize="lg"
                isDisabled
              />
              <InputRightElement height="60px">
                <IconButton
                  aria-label="Search"
                  icon={<SearchIcon />}
                  size="xl"
                  variant="ghost"
                  isDisabled
                />
              </InputRightElement>
            </InputGroup>
          </Box>
        </VStack>
      </VStack>

      {/* 카테고리 및 오늘의 단어 섹션 */}
      <VStack spacing={{ base: 8, sm: 9, md: 10 }} mt={{ base: 16, sm: 20, md: 24 }} width="100%">
        {/* 카테고리 섹션 */}
        <Heading size={{ base: "md", sm: "lg", md: "xl" }} alignSelf="flex-start">카테고리</Heading>
        <Grid templateColumns="repeat(4, 1fr)" gap={4} width="100%">
          {[...Array(8)].map((_, index) => (
            <GridItem key={index}>
              <Skeleton height="40px" width="100%" />
            </GridItem>
          ))}
        </Grid>

        {/* 오늘의 단어 섹션 */}
        <Heading size={{ base: "md", sm: "lg", md: "xl" }} alignSelf="flex-start">오늘의 단어</Heading>
        {isMobile ? (
          // 모바일 뷰
          <Box width="100%">
            <Skeleton height="200px" width="100%" />
          </Box>
        ) : (
          // 데스크톱 뷰
          <Grid templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }} gap={4} width="100%">
            {[...Array(3)].map((_, index) => (
              <GridItem key={index}>
                <Skeleton height="200px" width="100%" />
              </GridItem>
            ))}
          </Grid>
        )}
      </VStack>
    </Container>
  );
};

export default HomeSkeleton;