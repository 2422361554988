import React, { useMemo } from 'react';
import { 
  Box, 
  VStack, 
  Container,
  Heading,
  GridItem, 
  Grid, 
  useBreakpointValue,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getKeywords } from "../api";
import { IKeyword } from "../types";
import { Category } from './categories/Category';
import KeywordCard from './keyword/KeywordCard';
import AdCarousel from '../components/analytics/AdCarousel';
import Searches from '../components/Searches';
import HomeSkeleton from '../components/HomeSkeleton';

export default function Home() {
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });
  const dotColor = useColorModeValue("gray", "white");

  const { data: keywords, isLoading } = useQuery<IKeyword[]>(["keywords"], getKeywords, {
    onError: (error) => console.error("Failed to fetch keywords:", error)
  });

  const randomKeywords = useMemo(() => {
    if (keywords && keywords.length > 0) {
      const shuffled = [...keywords].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 3);
    }
    return [];
  }, [keywords]);

  const categories = useMemo(() => {
    if (keywords) {
      const categoryObj: { [key: string]: boolean } = {};
      keywords.forEach(keyword => {
        const category = typeof keyword.category === 'object' ? keyword.category.name : String(keyword.category);
        categoryObj[category] = true;
      });
      return Object.keys(categoryObj);
    }
    return [];
  }, [keywords]);

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: "slick-dots slick-thumb",
    customPaging: function(i: number) {
      return (
        <Box
          as="div"
          width="5px"
          height="5px"
          borderRadius="50%"
          mt={3}
          backgroundColor={dotColor}
        />
      );
    },
  };

  const handleSearch = (keyword: IKeyword) => {
    navigate(`/keywords/${keyword.pk}`);
  };

  if (isLoading) {
    return <HomeSkeleton />;
  }

  return (
    <Container 
      maxW={{ base: "100%", sm: "90%", md: "container.md" }} 
      centerContent 
      px={{ base: 4, sm: 6, md: 0 }}
      pb={{ base: 16, sm: 20, md: 28 }}
    >
      <VStack 
      spacing={4} 
      mt={{ base: 14, sm: 16, md: 20 }}
      mb={{ base: 14, sm: 16, md: 20 }}  
      width="100%"
      >
        <Searches onSearch={handleSearch} />
      </VStack>

      {/* AdCarousel 섹션 600x160px사진
      <Flex width="100%" mt={4} padding="20px" justifyContent="center" alignItems="center">
        <AdCarousel />
      </Flex>
      */}

      <VStack spacing={{ base: 8, sm: 9, md: 10 }} mt={{ base: 16, sm: 20, md: 24 }} width="100%">
        <Heading size={{ base: "md", sm: "lg", md: "xl" }} alignSelf="flex-start">카테고리</Heading>
        <Category categories={categories} />

        {randomKeywords.length > 0 && (
          <>
            <Heading size={{ base: "md", sm: "lg", md: "xl" }} alignSelf="flex-start">오늘의 단어</Heading>
            {isMobile ? (
              <Box width="100%">
                <Slider {...sliderSettings}>
                  {randomKeywords.map((keyword) => (
                    <Box key={keyword.pk} p={2}>
                      <KeywordCard keyword={keyword} onClick={() => navigate(`/keywords/${keyword.pk}`)} />
                    </Box>
                  ))}
                </Slider>
              </Box>
            ) : (
              <Grid templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }} gap={4} width="100%">
                {randomKeywords.map((keyword) => (
                  <GridItem key={keyword.pk}>
                    <KeywordCard keyword={keyword} onClick={() => navigate(`/keywords/${keyword.pk}`)} />
                  </GridItem>
                ))}
              </Grid>
            )}
          </>
        )}
      </VStack>
    </Container>
  );
}