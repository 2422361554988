import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box, Heading, Wrap, WrapItem, Text, useColorModeValue } from '@chakra-ui/react';
import { getRelatedKeywords } from '../../api';
import { IRelatedKeyword } from '../../types';
import { useNavigate } from 'react-router-dom';

interface KeywordRelatedProps {
  keywordId: string;
}

export default function KeywordRelated({ keywordId }: KeywordRelatedProps) {
  const navigate = useNavigate();

  const lengthColor = useColorModeValue('gray.500', 'white');

  const { data: relatedKeywords, isLoading } = useQuery<IRelatedKeyword[]>(
    ['relatedKeywords', keywordId],
    () => getRelatedKeywords(keywordId)
  );

  if (isLoading) return <Text>Loading related keywords...</Text>;

  return (
    <Box>
      <Box mb={4}>
        <Box display="inline-block" position="relative">
          <Box
            position="absolute"
            bottom="2px"
            left="0"
            width="100%"
            height="8px"
            bg="yellow.300"
            opacity={0.5}
            zIndex={0}
          />
          <span style={{ position: 'relative', zIndex: 1 }}>
            <Heading as="h3" size="md" display="inline">
              관련 키워드
            </Heading>
            <Text as="span" ml={2} color={lengthColor} fontSize="sm" fontWeight="bold">
              {relatedKeywords?.length || 0}건
            </Text>
          </span>
        </Box>
      </Box>
      <Wrap spacing={3}>
        {relatedKeywords?.slice(0, 4).map((relatedKeyword) => (
          <WrapItem key={relatedKeyword.related.pk}>
            <Box
              as="button"
              bg="blue.300"
              color="white"
              px={4}
              py={2}
              borderRadius="full"
              fontWeight="semibold"
              fontSize="md"
              cursor="pointer"
              onClick={() => navigate(`/keywords/${relatedKeyword.related.pk}`)}
              _hover={{ bg: 'blue.400' }}
              transition="all 0.2s"
              boxShadow="md"
            >
              {relatedKeyword.related.english_term}
            </Box>
          </WrapItem>
        ))}
      </Wrap>
    </Box>
  );
}