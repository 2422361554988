import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getMyProfile } from "../api";

export default function useUserProfile() {
    const queryClient = useQueryClient();
    const { isLoading, data, isError } = useQuery({
        queryKey: ["users", "my-profile"],
        queryFn: getMyProfile,
        retry: false,
    });

    const mutate = () => queryClient.invalidateQueries(["users", "my-profile"]);

    return {
        userLoading: isLoading,
        profile: data,
        isLoggedIn: !isError,
        mutate,
    };
}