import React, { useState, useRef, useEffect } from 'react';
import {
  Box, Flex, Avatar, Link, IconButton, Button, useDisclosure,
  useColorMode, Text, useToast, Drawer, DrawerBody, DrawerHeader,
  DrawerOverlay, DrawerContent, DrawerCloseButton, VStack,
  useColorModeValue, Image, AlertDialog, AlertDialogBody,
  AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
  Spinner, Input, InputGroup, InputLeftElement, Divider
} from '@chakra-ui/react';
import { HamburgerIcon, MoonIcon, SunIcon, SearchIcon } from '@chakra-ui/icons';
import { 
  IoSearchOutline, 
  IoBookOutline, 
  IoTrendingUpOutline, 
  IoLogInOutline,
  IoSettingsOutline, 
  IoImageOutline,
} from 'react-icons/io5';
import { BiArrowToLeft } from "react-icons/bi";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useUser from '../lib/useUser';
import useUserProfile from "../lib/useUserProfile";
import LoginModal from './LoginModal';
import SignUpModal from './SignUpModal';
import PrivateSettings from './PrivateSettings';
import SearchesModal from './SearchesModal';
import { IKeyword } from '../types';

const LOGO_PATH_LIGHT = '/images/logo-light.png';
const LOGO_PATH_DARK = '/images/logo-dark.png';
const LOGO_SUB_LIGHT = '/images/logosub-light.png';
const LOGO_SUB_DARK = '/images/logosub-dark.png';

export default function Header() {
  const { userLoading, isLoggedIn, user, mutate: mutateUser } = useUser();
  const { profile, mutate: mutateProfile } = useUserProfile();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showPrivateSettings, setShowPrivateSettings] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();
  const cancelRef = useRef(null);
  const [isFirstLogin, setIsFirstLogin] = useState(true);
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);

  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onClose: onLoginClose,
  } = useDisclosure();
  const {
    isOpen: isSignUpOpen,
    onOpen: onSignUpOpen,
    onClose: onSignUpClose,
  } = useDisclosure();
  const {
    isOpen: isLibraryAlertOpen,
    onOpen: onLibraryAlertOpen,
    onClose: onLibraryAlertClose,
  } = useDisclosure();
  const {
    isOpen: isSearchModalOpen,
    onOpen: onSearchModalOpen,
    onClose: onSearchModalClose,
  } = useDisclosure();

  const logoPath = useColorModeValue(LOGO_PATH_LIGHT, LOGO_PATH_DARK);
  const logoSubPath = useColorModeValue(LOGO_SUB_LIGHT, LOGO_SUB_DARK);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDrawerOpen = () => {
    onOpen();
  };

  const handleSettingsClick = () => {
    mutateProfile();
    setShowPrivateSettings(true);
  };

  const handleBackClick = () => {
    setShowPrivateSettings(false);
  };

  const handleLibraryClick = () => {
    if (!isLoggedIn) {
      onLibraryAlertOpen();
    } else {
      navigate('/saved-keywords');
      onClose();
    }
  };

  const handleLogoutSuccess = () => {
    setShowPrivateSettings(false);
    onClose();
    setIsFirstLogin(true);
  };

  const handleLoginSuccess = async () => {
    if (isFirstLogin) {
      await Promise.all([mutateUser(), mutateProfile()]);
      setIsFirstLogin(false);
    }
    onLoginClose();
  };

  useEffect(() => {
    if (isLoggedIn && !profile && isFirstLogin) {
      mutateProfile();
    }
  }, [isLoggedIn, profile, mutateProfile, isFirstLogin]);
  
  const headerBg = useColorModeValue('gray.50', 'gray.900');

  return (
    <>
      <Box 
        bg={headerBg}
        px={4}
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
      >
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={<HamburgerIcon />}
            aria-label={'Open Menu'}
            onClick={handleDrawerOpen}
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          />
          <Link
            as={RouterLink}
            to="/"
            _hover={{ textDecoration: 'none' }}
          >
             <Image 
              src={logoPath} 
              alt="Wordjourney" 
              height={{ base: "30px", md: "40px" }} 
              width="auto"
              objectFit="contain"
            />
          </Link>
          <IconButton
            size={'md'}
            icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            aria-label={'Toggle Color Mode'}
            onClick={toggleColorMode}
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          />
        </Flex>
      </Box>

      {/* Add a placeholder to prevent content from being hidden under the fixed header */}
      <Box height="64px" />

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <IconButton
              icon={<BiArrowToLeft />}
              variant="ghost"
              position="absolute"
              right={2}
              top={2}
              mt={1}
              onClick={onClose}
              aria-label="Close drawer"
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
              fontSize="24px"
            />
         
          <DrawerHeader>
            <Image 
                src={logoSubPath}
                alt="Wordjourney"
                width="100%"
                maxWidth="100px"
                height="auto"
                objectFit="contain"
              />
          </DrawerHeader>

          <DrawerBody>
            {showPrivateSettings ? (
              <PrivateSettings 
                onBack={handleBackClick} 
                onClose={onClose} 
                onLogoutSuccess={handleLogoutSuccess}
              />
            ) : (
              <VStack spacing={4} align="stretch" height="100%">
                {userLoading ? (
                  <Flex justifyContent="center" alignItems="center" height="100px">
                    <Spinner />
                  </Flex>
                ) : (
                  <>
                    <VStack spacing={4} align="stretch" flex={1}>
                      {isLoggedIn && (
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="키워드를 찾아볼까요?"
                            borderRadius="full"
                            onClick={onSearchModalOpen}
                            readOnly
                            cursor="pointer"
                          />
                        </InputGroup>
                      )}
                      <Button 
                        leftIcon={<IoSearchOutline />} 
                        as={RouterLink} to="/" 
                        variant="ghost" 
                        justifyContent="flex-start" 
                        onClick={onClose}
                      >
                        홈
                      </Button>
                      <Button 
                        leftIcon={<IoImageOutline />} 
                        as={RouterLink} 
                        to="/gallery" 
                        variant="ghost" 
                        justifyContent="flex-start" 
                        onClick={onClose}
                      >
                        갤러리
                      </Button>
                      <Button 
                        leftIcon={<IoTrendingUpOutline />} 
                        as={RouterLink} 
                        to="/trend" 
                        variant="ghost" 
                        justifyContent="flex-start" 
                        onClick={onClose}
                      >
                        트렌드
                      </Button>
                      <Button 
                        leftIcon={<IoBookOutline />} 
                        variant="ghost" 
                        justifyContent="flex-start"
                        onClick={handleLibraryClick}
                      >
                        도서관
                      </Button>
                      {!isLoggedIn && (
                        <>
                          <Button
                            leftIcon={<IoLogInOutline />}
                            onClick={() => {
                              onClose();
                              onLoginOpen();
                            }}
                            variant="ghost"
                            justifyContent="flex-start"
                            width="100%"
                          >
                            로그인
                          </Button>
                          <Button
                            onClick={() => {
                              onClose();
                              onSignUpOpen();
                            }}
                            bg="blue.300"
                            color="white"
                            _hover={{ bg: 'blue.400' }}
                            _active={{ bg: 'blue.500' }}
                            borderRadius="full"
                            mt={4}
                            width="100%"
                          >
                            계정 만들기
                          </Button>
                        </>
                      )}
                    </VStack>
                    
                    {isLoggedIn && profile && (
                      <>
                        <Divider mt="auto" />
                        <Box mt="auto" pt={2}>
                          <Flex 
                            alignItems="flex-start" 
                            mb={8}
                          >
                            <Avatar 
                              name={profile.nickname} 
                              src={profile.avatar} 
                              mr={3} 
                              size={{ base: "sm", md: "md"}}
                            />
                            <Flex direction="column" flex={1}>
                              <Text fontWeight="bold">{profile.nickname || '사용자'}님, 환영합니다!</Text>
                              <Flex alignItems="center" mt={1}>
                                <Text fontSize="sm" color="gray.500">
                                  {user?.email}
                                </Text>
                                <IconButton
                                  aria-label="Settings"
                                  icon={<IoSettingsOutline />}
                                  size="sm"
                                  variant="ghost"
                                  colorScheme="gray"
                                  color="gray.500"
                                  ml={2}
                                  onClick={handleSettingsClick}
                                />
                              </Flex>
                            </Flex>
                          </Flex>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </VStack>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <AlertDialog
        isOpen={isLibraryAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onLibraryAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
            로그인하고 키워드를 저장하세요!
            </AlertDialogHeader>

            <AlertDialogBody>
            도서관은 중요한 키워드를 저장하고 관리할 수 있습니다. 지금 시작하시겠습니까?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onLibraryAlertClose}>
                취소
              </Button>
              <Button colorScheme="blue" onClick={() => {
                onLibraryAlertClose();
                onLoginOpen();
              }} ml={3}>
                로그인
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <SignUpModal
        isOpen={isSignUpOpen}
        onClose={onSignUpClose}
        onLoginOpen={onLoginOpen}
      />
      <LoginModal
        isOpen={isLoginOpen}
        onClose={onLoginClose}
        onSignUpOpen={onSignUpOpen}
        onLoginSuccess={handleLoginSuccess}
      />
      <SearchesModal
        isOpen={isSearchModalOpen}
        onClose={() => {
          onSearchModalClose();
          onClose(); // 햄버거 메뉴 닫기
        }}
      />
    </>
  );
}