import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  VStack,
  Text,
  Flex,
  Spinner,
  useBreakpointValue,
  Heading,
  Icon,
  useColorMode,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { getKeywords } from "../api";
import { IKeyword } from "../types";

// Helper function to get color based on mode
const getColor = (colorMode: string, lightValue: string, darkValue: string) => {
  return colorMode === 'light' ? lightValue : darkValue;
};

interface SearchesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SearchesModal({ isOpen, onClose }: SearchesModalProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { colorMode } = useColorMode();

  // Color values
  const bgColor = getColor(colorMode, "white", "gray.900");
  const modalBgColor = getColor(colorMode, "rgba(255, 255, 255, 0.8)", "rgba(26, 32, 44, 0.8)");
  const textColor = getColor(colorMode, "gray.800", "gray.100");
  const borderColor = getColor(colorMode, "gray.200", "gray.600");
  const iconColor = getColor(colorMode, "gray.500", "gray.400");
  const hoverBgColor = getColor(colorMode, "rgba(0, 0, 0, 0.05)", "rgba(255, 255, 255, 0.05)");
  const secondaryTextColor = getColor(colorMode, "gray.500", "gray.400");

  const modalPadding = useBreakpointValue({ base: 6, md: 8 });
  const contentPadding = useBreakpointValue({ base: 4, md: 6 });

  const { data: keywords } = useQuery<IKeyword[]>(["keywords"], getKeywords, {
    onError: (error) => console.error("Failed to fetch keywords:", error)
  });

  const filteredKeywords = useMemo(() => {
    if (!keywords) return [];
    return keywords
      .filter(keyword =>
        keyword.english_term.toLowerCase().includes(searchTerm.toLowerCase()) ||
        keyword.korean_term.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .slice(0, 5);  // 결과를 5개로 제한
  }, [keywords, searchTerm]);

  const handleSearch = useCallback(async (keyword?: IKeyword) => {
    setIsLoading(true);
    try {
      if (keyword) {
        navigate(`/keywords/${keyword.pk}`);
      } else if (filteredKeywords && filteredKeywords.length > 0) {
        navigate(`/keywords/${filteredKeywords[0].pk}`);
      }
      onClose();
    } finally {
      setIsLoading(false);
    }
  }, [navigate, onClose, filteredKeywords]);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }, [handleSearch]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent
        bg={modalBgColor}
        boxShadow="xl"
        borderRadius="xl"
        mx={modalPadding}
      >
        <ModalBody py={contentPadding}>
          <VStack spacing={4}>
            <InputGroup size="lg">
              <Input
                placeholder="무엇이든 찾아보세요!"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setTimeout(() => setIsInputFocused(false), 200)}
                borderColor={isInputFocused ? "blue.500" : borderColor}
                borderWidth={isInputFocused ? "2px" : "1px"}
                _hover={{ borderColor: "blue.300" }}
                _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce" }}
                height="60px"
                fontSize="lg"
                bg={bgColor}
                color={textColor}
                isDisabled={isLoading}
              />

              <InputRightElement height="60px">
                {isLoading ? (
                  <Spinner size="sm" color="blue.500" />
                ) : (
                  <IconButton
                    aria-label="Search"
                    icon={<BsArrowRightCircleFill />}
                    size="xl"
                    color={textColor}
                    variant="ghost"
                    onClick={() => handleSearch()}
                    _hover={{ bg: "transparent", color: "gray.500" }}
                    _active={{ bg: "transparent", color: "gray.700" }}
                    isDisabled={isLoading}
                  />
                )}
              </InputRightElement>
            </InputGroup>

            {filteredKeywords && filteredKeywords.length > 0 && (
              <>
                <Heading as="h3" size="md" alignSelf="flex-start" mb={2} color={textColor}>
                  추천 키워드
                </Heading>
                <VStack align="stretch" width="100%" maxHeight="300px" overflowY="auto">
                  {filteredKeywords.map((keyword) => (
                    <Flex
                      key={keyword.pk}
                      align="center"
                      cursor="pointer"
                      onClick={() => handleSearch(keyword)}
                      _hover={{ bg: hoverBgColor }}
                      p={2}
                      borderRadius="md"
                      transition="all 0.2s"
                    >
                      <Icon as={SearchIcon} mr={2} color={iconColor} />
                      <Text fontWeight="bold" color={textColor}>{keyword.english_term}</Text>
                      <Text ml={2} color={secondaryTextColor}>({keyword.korean_term})</Text>
                    </Flex>
                  ))}
                </VStack>
              </>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}