import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  useToast,
  List,
  ListItem,
  ListIcon,
  Checkbox,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { changePW, IChangePwVariables } from '../../api';
import ProtectedPage from '../../components/ProtectedPage';

interface PasswordRequirements {
  length: boolean;
  letterAndNumber: boolean;
  special: boolean;
}

export default function ChangePw() {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<IChangePwVariables>();
  
  const toast = useToast();
  const navigate = useNavigate();

  const [showPasswords, setShowPasswords] = useState(false);
  const [isNewPasswordFocused, setIsNewPasswordFocused] = useState(false);
  const [isNewPasswordEntered, setIsNewPasswordEntered] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState<PasswordRequirements>({
    length: false,
    letterAndNumber: false,
    special: false,
  });

  const oldPassword = watch("old_password");
  const newPassword = watch("new_password");

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const mutation = useMutation(changePW, {
    onSuccess: () => {
      toast({
        status: 'success',
        title: '비밀번호 변경 완료',
        description: '비밀번호가 성공적으로 변경되었습니다.',
        position: 'bottom-right',
      });
      navigate('/');
    },
    onError: (error: any) => {
      if (error.response?.status === 400) {
        setError("old_password", {
          type: "manual",
          message: "현재 비밀번호를 확인해주세요.",
        });
      } else {
        toast({
          status: 'error',
          title: '오류 발생',
          description: '비밀번호 변경에 실패했습니다. 다시 시도해 주세요.',
          position: 'bottom-right',
        });
      }
    },
  });

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setIsNewPasswordEntered(password.length > 0);
    setPasswordRequirements({
      length: password.length >= 8,
      letterAndNumber: /^(?=.*[A-Za-z])(?=.*\d).+$/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const onSubmit = (data: IChangePwVariables) => {
    if (data.old_password === data.new_password) {
      setError("new_password", {
        type: "manual",
        message: "새 비밀번호는 현재 비밀번호와 달라야 합니다.",
      });
      return;
    }

    if (!passwordRequirements.length || !passwordRequirements.letterAndNumber || !passwordRequirements.special) {
      setError("new_password", {
        type: "manual",
        message: "새 비밀번호는 8자 이상이며, 문자, 숫자, 특수문자를 포함해야 합니다.",
      });
      return;
    }

    mutation.mutate(data);
  };

  useEffect(() => {
    if (newPassword && oldPassword === newPassword) {
      setError("new_password", {
        type: "manual",
        message: "새 비밀번호는 현재 비밀번호와 달라야 합니다.",
      });
    } else {
      setError("new_password", { type: "manual", message: "" });
    }
  }, [newPassword, oldPassword, setError]);

  return (
    <ProtectedPage>
      <Box py={12} px={4} bg={bgColor} minHeight="100vh">
        <Container maxW="container.sm">
          <Box bg={cardBgColor} p={8} borderRadius="xl" boxShadow="lg" border="1px" borderColor={borderColor}>
            <Heading textAlign="center" mb={6} fontSize={{ base: "2xl", md: "3xl" }}>비밀번호 변경</Heading>
            <VStack
              spacing={6}
              as="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl isRequired isInvalid={Boolean(errors.old_password)}>
                <FormLabel>현재 비밀번호</FormLabel>
                <Input
                  {...register("old_password", {
                    required: "현재 비밀번호를 입력해주세요",
                  })}
                  type={showPasswords ? "text" : "password"}
                  size="lg"
                />
                <FormErrorMessage>
                  {errors.old_password && errors.old_password.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={Boolean(errors.new_password)}>
                <FormLabel>새 비밀번호</FormLabel>
                <Input
                  {...register("new_password", {
                    required: "새 비밀번호를 입력해주세요",
                    onChange: handleNewPasswordChange,
                  })}
                  type={showPasswords ? "text" : "password"}
                  onFocus={() => setIsNewPasswordFocused(true)}
                  onBlur={() => setIsNewPasswordFocused(false)}
                  size="lg"
                />
                <FormErrorMessage>
                  {errors.new_password && errors.new_password.message}
                </FormErrorMessage>

                {(isNewPasswordFocused || isNewPasswordEntered) && (
                  <List spacing={1} mt={2}>
                    <ListItem>
                      <ListIcon as={passwordRequirements.letterAndNumber ? FaCheck : FaTimes} color={passwordRequirements.letterAndNumber ? "green.500" : "red.500"} />
                      <Text as="span" fontSize="sm" color={passwordRequirements.letterAndNumber ? "green.500" : "red.500"}>
                        문자 + 숫자 포함
                      </Text>
                    </ListItem>
                    <ListItem>
                      <ListIcon as={passwordRequirements.length ? FaCheck : FaTimes} color={passwordRequirements.length ? "green.500" : "red.500"} />
                      <Text as="span" fontSize="sm" color={passwordRequirements.length ? "green.500" : "red.500"}>
                        8자 이상
                      </Text>
                    </ListItem>
                    <ListItem>
                      <ListIcon as={passwordRequirements.special ? FaCheck : FaTimes} color={passwordRequirements.special ? "green.500" : "red.500"} />
                      <Text as="span" fontSize="sm" color={passwordRequirements.special ? "green.500" : "red.500"}>
                        특수문자 포함
                      </Text>
                    </ListItem>
                  </List>
                )}
              </FormControl>

              <FormControl isRequired isInvalid={Boolean(errors.re_new_password)}>
                <FormLabel>새 비밀번호 확인</FormLabel>
                <Input
                  {...register("re_new_password", {
                    required: "새 비밀번호를 다시 입력해주세요",
                    validate: (value) => value === watch('new_password') || "새 비밀번호를 확인해주세요",
                  })}
                  type={showPasswords ? "text" : "password"}
                  size="lg"
                />
                <FormErrorMessage>
                  {errors.re_new_password && errors.re_new_password.message}
                </FormErrorMessage>
              </FormControl>

              <Flex width="100%" justifyContent="flex-start">
                <Checkbox 
                  onChange={(e) => setShowPasswords(e.target.checked)}
                  colorScheme="blue"
                  size="md"
                >
                  비밀번호 표시
                </Checkbox>
              </Flex>

              <Button
                isLoading={mutation.isLoading}
                type="submit"
                colorScheme="blue"
                w="100%"
                size="lg"
                fontSize="md"
              >
                비밀번호 변경
              </Button>
            </VStack>
          </Box>
        </Container>
      </Box>
    </ProtectedPage>
  );
}