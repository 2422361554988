import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Container,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { FiCheckCircle, FiAlertCircle, FiLogIn } from 'react-icons/fi';

export default function ResetPwComplete() {
  const location = useLocation();
  const status = new URLSearchParams(location.search).get('status');

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const iconColor = status === 'error' ? 'red.500' : 'green.500';
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const subTextColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Box bg={bgColor} minH="100vh" py={12}>
      <Container maxW="container.sm">
        <Box bg={cardBgColor} p={8} borderRadius="xl" boxShadow="xl">
          <VStack spacing={6} align="stretch">
            <Icon
              as={status === 'error' ? FiAlertCircle : FiCheckCircle}
              w={16}
              h={16}
              color={iconColor}
              margin="auto"
            />
            <Heading textAlign="center" fontSize={{ base: "2xl", md: "3xl" }} mb={2}>
              {status === 'error' ? '비밀번호 재설정 오류' : '비밀번호 재설정 완료'}
            </Heading>
            
            {status === 'error' ? (
              <Text fontSize="lg" textAlign="center" color={textColor}>
                비밀번호 재설정 중 문제가 발생했습니다. 다시 시도해 주세요.
              </Text>
            ) : (
              <>
                <Text fontSize="lg" textAlign="center" color={textColor}>
                  새 비밀번호가 성공적으로 설정되었습니다.
                </Text>
                <Text fontSize="md" textAlign="center" color={subTextColor}>
                  이제 새 비밀번호로 로그인할 수 있습니다.
                </Text>
              </>
            )}
            
            <Button
              as={RouterLink}
              to="/"
              colorScheme={status === 'error' ? "red" : "green"}
              size="lg"
              width="full"
              mt={4}
              leftIcon={<FiLogIn />}
            >
              로그인 페이지로 이동
            </Button>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
}