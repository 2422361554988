import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  SimpleGrid,
  Text,
  Heading,
  useColorModeValue,
  VStack,
  HStack,
  IconButton,
  useToken,
  useBreakpointValue,
  Container,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronUpIcon, ChevronDownIcon, TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons';
import { getTopSearches, getKeywords } from '../../api';

interface ITopSearch {
  search_term: string;
  count: number;
  isNew: boolean;
  change: 'up' | 'down' | 'same' | 'new';
}

interface IKeyword {
  pk: number;
  english_term: string;
  // 다른 필요한 필드들...
}

const MotionBox = motion(Box);

const PopularSearches: React.FC = () => {
  const [searchTerms, setSearchTerms] = useState<ITopSearch[]>([]);
  const [keywords, setKeywords] = useState<IKeyword[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(() => {
    const savedState = localStorage.getItem('popularSearchesIsOpen');
    return savedState ? JSON.parse(savedState) : false;
  });

  const navigate = useNavigate();

  const bgColor = useColorModeValue('white', 'gray.800');
  const cardBgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const iconColor = useColorModeValue('gray.600', 'gray.400');
  const [blue400, blue500] = useToken('colors', ['blue.400', 'blue.500']);
  const hoverBgColor = useColorModeValue('gray.100', 'gray.600');

  const isMobile = useBreakpointValue({ base: true, sm: false });
  const columns = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [searchData, keywordData] = await Promise.all([
          getTopSearches(20),
          getKeywords()
        ]);
        setSearchTerms(searchData);
        setKeywords(keywordData);
        setError(null);
      } catch (err) {
        setError('데이터를 불러오는 데 실패했습니다.');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // 1분마다 갱신

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      localStorage.setItem('popularSearchesIsOpen', JSON.stringify(isOpen));
    }
  }, [isOpen, isMobile]);

  const RankChangeIcon = ({ change }: { change: 'up' | 'down' | 'same' | 'new' }) => {
    switch (change) {
      case 'up':
        return <TriangleUpIcon color="green.500" />;
      case 'down':
        return <TriangleDownIcon color="red.500" />;
      case 'new':
        return (
          <Text
            as="span"
            fontSize="xs"
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing="wider"
            bgGradient={`linear(to-r, ${blue400}, ${blue500})`}
            bgClip="text"
            sx={{
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
            }}
          >
            NEW
          </Text>
        );
      case 'same':
        return <Text fontSize="sm" color={iconColor}>-</Text>;
      default:
        return null;
    }
  };

  const handleKeywordClick = (searchTerm: string) => {
    const keyword = keywords.find(k => k.english_term.toLowerCase() === searchTerm.toLowerCase());
    if (keyword) {
      navigate(`/keywords/${keyword.pk}`);
    } else {
      console.warn(`No matching keyword found for search term: ${searchTerm}`);
    }
  };

  const renderSearchTerm = (term: ITopSearch, index: number) => (
    <Box
      key={term.search_term}
      bg={cardBgColor}
      p={4}
      borderRadius="md"
      boxShadow="sm"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      cursor="pointer"
      onClick={() => handleKeywordClick(term.search_term)}
      _hover={{ bg: hoverBgColor }}
      transition="background-color 0.2s"
    >
      <HStack spacing={3}>
        <Text
          fontSize="sm"
          fontWeight="bold"
          color={index < 3 ? 'red.500' : 'gray.500'}
        >
          {index + 1}
        </Text>
        <Text fontWeight="medium" color={textColor}>
          {term.search_term}
        </Text>
      </HStack>
      <RankChangeIcon change={term.change} />
    </Box>
  );

  if (isLoading) {
    return <Box textAlign="center" p={4}>불러오는 중...</Box>;
  }

  if (error) {
    return <Box textAlign="center" color="red.500" p={4}>{error}</Box>;
  }

  return (
    <Container maxW="container.xl" px={{ base: 4, md: 8 }}>
      <Box 
        bg={bgColor} 
        p={{ base: 4, md: 6 }}
        borderRadius="xl" 
        width="100%" 
        transition="all 0.3s"
      >
        <VStack spacing={4} align="stretch">
          <HStack justifyContent="space-between" alignItems="center">
            <Heading as="h2" size="lg" color={textColor}>
              실시간 인기 검색어
            </Heading>
            {!isMobile && (
              <IconButton
                aria-label={isOpen ? '접기' : '펼치기'}
                icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                onClick={() => setIsOpen(!isOpen)}
                variant="ghost"
                color={iconColor}
              />
            )}
          </HStack>

          <AnimatePresence mode="wait">
            {(isMobile || !isOpen) ? (
              <MotionBox
                key="collapsed"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <VStack spacing={2} align="stretch">
                  {searchTerms.slice(0, 5).map((term, index) => renderSearchTerm(term, index))}
                </VStack>
              </MotionBox>
            ) : (
              <MotionBox
                key="expanded"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <SimpleGrid columns={columns} spacing={4}>
                  {searchTerms.map((term, index) => renderSearchTerm(term, index))}
                </SimpleGrid>
              </MotionBox>
            )}
          </AnimatePresence>
        </VStack>
      </Box>
    </Container>
  );
};

export default PopularSearches;