import React from 'react';
import { Box, Container, Heading, VStack, useBreakpointValue } from '@chakra-ui/react';
import PopularSearches from '../../components/analytics/PopularSearches';

export default function Trend() {
  const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' });
  const containerPadding = useBreakpointValue({ base: 4, md: 8 });
  const containerMaxWidth = useBreakpointValue({ base: 'container.sm', md: 'container.md', lg: 'container.lg' });

  return (
    <Container 
      maxW={containerMaxWidth} 
      py={containerPadding}
      px={{ base: 6, md: 10, lg: 16 }}
    >
      <VStack spacing={4} align="stretch">
        <Heading as="h3" size={headingSize} textAlign="center">
          트렌드 & 인사이트
        </Heading>
        <Box>
          <PopularSearches />
        </Box>
      </VStack>
    </Container>
  );
}