import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
  Container,
  Text,
  useColorModeValue,
  Checkbox,
  Icon,
} from '@chakra-ui/react';
import { FiLock } from 'react-icons/fi';
import { resetPasswordConfirm } from '../../api';

interface IForm {
  new_password: string;
  re_new_password: string;
}

export default function ResetPwConfirm() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<IForm>();
  const navigate = useNavigate();
  const { uid, token } = useParams<{ uid: string; token: string }>();
  const [showPassword, setShowPassword] = React.useState(false);

  const mutation = useMutation(
    (data: { uid: string; token: string; new_password: string }) => resetPasswordConfirm(data),
    {
      onSuccess: () => {
        navigate('/password-reset-complete');
      },
      onError: () => {
        navigate('/password-reset-complete?status=error');
      },
    }
  );

  const onSubmit = (data: IForm) => {
    if (uid && token) {
      mutation.mutate({ uid, token, new_password: data.new_password });
    }
  };

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  return (
    <Box bg={bgColor} minH="100vh" py={12}>
      <Container maxW="container.sm">
        <Box bg={cardBgColor} p={8} borderRadius="xl" boxShadow="xl">
          <VStack spacing={6} align="stretch">
            <Icon as={FiLock} w={10} h={10} color="blue.500" alignSelf="center" />
            <Heading textAlign="center" fontSize={{ base: "2xl", md: "3xl" }} mb={2}>
              새 비밀번호 설정
            </Heading>
            <Text textAlign="center" color="gray.500" fontSize="md">
              안전한 새 비밀번호를 입력해주세요.
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing={4} align="stretch">
                <FormControl isInvalid={!!errors.new_password}>
                  <FormLabel>새 비밀번호</FormLabel>
                  <Input
                    type={showPassword ? "text" : "password"}
                    {...register('new_password', {
                      required: '새 비밀번호를 입력해주세요',
                      minLength: { value: 8, message: '비밀번호는 최소 8자 이상이어야 합니다' },
                    })}
                    placeholder="새 비밀번호 입력"
                  />
                  <FormErrorMessage>{errors.new_password?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.re_new_password}>
                  <FormLabel>새 비밀번호 확인</FormLabel>
                  <Input
                    type={showPassword ? "text" : "password"}
                    {...register('re_new_password', {
                      required: '새 비밀번호를 다시 입력해주세요',
                      validate: (value) => value === watch('new_password') || '비밀번호가 일치하지 않습니다',
                    })}
                    placeholder="새 비밀번호 재입력"
                  />
                  <FormErrorMessage>{errors.re_new_password?.message}</FormErrorMessage>
                </FormControl>
                <Box alignSelf="flex-start">
                  <Checkbox 
                    onChange={(e) => setShowPassword(e.target.checked)}
                    colorScheme="blue"
                    size="sm"
                  >
                    비밀번호 표시
                  </Checkbox>
                </Box>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={mutation.isLoading}
                  loadingText="처리 중..."
                  size="lg"
                  width="full"
                  mt={4}
                >
                  새 비밀번호 설정
                </Button>
              </VStack>
            </form>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
}