import React, { useState, useMemo, useEffect } from 'react';
import { 
  Box, 
  Input, 
  VStack, 
  Text, 
  Container, 
  Heading, 
  InputGroup, 
  InputRightElement,
  IconButton,
  Flex,
  useColorModeValue
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import { getKeywords } from "../api";
import { IKeyword } from "../types";
import { Category } from '../routes/categories/Category';

const MAX_RECENT_SEARCHES = 5;

export const SmallWindow: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [recentSearches, setRecentSearches] = useState<IKeyword[]>([]);
  const navigate = useNavigate();

  // Color mode values
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBgColor = useColorModeValue("gray.100", "gray.700");
  const placeholderColor = useColorModeValue("gray.400", "gray.500");

  const { data: keywords } = useQuery<IKeyword[]>(["keywords"], getKeywords, {
    onError: (error) => console.error("Failed to fetch keywords:", error)
  });

  const filteredKeywords = useMemo(() => {
    return keywords?.filter(keyword =>
      keyword.english_term.toLowerCase().includes(searchTerm.toLowerCase()) ||
      keyword.korean_term.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [keywords, searchTerm]);

  const categories = useMemo(() => {
    if (keywords) {
      const categoryObj: { [key: string]: boolean } = {};
      keywords.forEach(keyword => {
        const category = typeof keyword.category === 'object' ? keyword.category.name : String(keyword.category);
        categoryObj[category] = true;
      });
      return Object.keys(categoryObj);
    }
    return [];
  }, [keywords]);

  useEffect(() => {
    const savedSearches = localStorage.getItem('recentSearches');
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
  }, []);

  const saveRecentSearch = (keyword: IKeyword) => {
    const updatedSearches = [keyword, ...recentSearches.filter(item => item.pk !== keyword.pk)].slice(0, MAX_RECENT_SEARCHES);
    setRecentSearches(updatedSearches);
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
  };

  const handleSearch = (keyword: IKeyword) => {
    saveRecentSearch(keyword);
    navigate(`/keywords/${keyword.pk}`);
  };

  const handleSearchSubmit = () => {
    if (!searchTerm.trim()) {
      alert("검색어를 입력해주세요.");
      return;
    }
  
    if (filteredKeywords && filteredKeywords.length > 0) {
      handleSearch(filteredKeywords[0]);
    } else {
      alert("검색 결과가 없습니다.");
    }
  };

  const handleRemoveRecentSearch = (pk: number) => {
    const updatedSearches = recentSearches.filter(item => item.pk !== pk);
    setRecentSearches(updatedSearches);
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
  };

  return (
    <Container maxW="360px" centerContent px={4} bg={bgColor} color={textColor}>
      <VStack spacing={6} mt="60px" width="100%">
        <Box width="100%" position="relative">
          <InputGroup size="md">
            <Input
              placeholder="검색"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearchSubmit();
                }
              }}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setTimeout(() => setIsInputFocused(false), 200)}
              borderColor={borderColor}
              _hover={{ borderColor: "blue.300" }}
              _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce" }}
              bg={bgColor}
              color={textColor}
              _placeholder={{ color: placeholderColor }}
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={handleSearchSubmit}
                size="sm"
                variant="ghost"
                color={textColor}
                _hover={{ bg: "transparent", color: "gray.500" }}
                _active={{ bg: "transparent", color: "gray.700" }}
              />
            </InputRightElement>
          </InputGroup>
          
          {isInputFocused && (
            <Box
              position="absolute"
              top="100%"
              left={0}
              right={0}
              bg={bgColor}
              borderRadius="md"
              boxShadow="md"
              zIndex={1}
              mt={2}
              p={2}
              borderWidth="1px"
              borderColor={borderColor}
            >
              {searchTerm && filteredKeywords && filteredKeywords.length > 0 ? (
                filteredKeywords.slice(0, 5).map((keyword) => (
                  <Text
                    key={keyword.pk}
                    cursor="pointer"
                    onClick={() => handleSearch(keyword)}
                    _hover={{ bg: hoverBgColor }}
                    p={2}
                  >
                    {keyword.english_term} ({keyword.korean_term})
                  </Text>
                ))
              ) : (
                <>
                  <Text fontSize="xs" fontWeight="bold" color={textColor} mb={2}>최근 검색</Text>
                  {recentSearches.map((keyword, index) => (
                    <Flex key={index} justify="space-between" align="center" p={2} _hover={{ bg: hoverBgColor }}>
                      <Text cursor="pointer" onClick={() => handleSearch(keyword)}>
                        {keyword.english_term} ({keyword.korean_term})
                      </Text>
                      <IconButton
                        aria-label="Remove search"
                        icon={<CloseIcon />}
                        size="xs"
                        variant="ghost"
                        onClick={() => handleRemoveRecentSearch(keyword.pk)}
                        color={textColor}
                      />
                    </Flex>
                  ))}
                </>
              )}
            </Box>
          )}
        </Box>
        
        <VStack spacing={4} width="100%">
          <Heading mt={5} size="sm" alignSelf="flex-start" color={textColor}>카테고리</Heading>
          <Category categories={categories} />
        </VStack>
      </VStack>
    </Container>
  );
}

export default SmallWindow;