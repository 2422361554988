import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Textarea,
  VStack,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
import { submitKeywordFeedback, submitExampleFeedback } from '../api';
import { Link } from 'react-router-dom';

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  feedbackType: 'good' | 'bad';
  targetId: string;
  targetType: 'keyword' | 'example';
}

interface FeedbackData {
  feedback_type: 'good' | 'bad';
  content?: string;
  reason?: string;
  other_reason?: string;
}

const REASON_CHOICES = [
    { value: 'unexpected_result', label: '예상과 다른 결과가 나와요' },
    { value: 'incorrect_content', label: '내용이 잘못되었어요' },
    { value: 'need_more_info', label: '설명이 부족해요' },
    { value: 'ineffective_example', label: '예시가 적절하지 않아요' },
    { value: 'better_alternative', label: '더 나은 대안이 있어요' },
    { value: 'other', label: '기타' },
];

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen,
  onClose,
  feedbackType,
  targetId,
  targetType,
}) => {
  const [content, setContent] = useState('');
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [reasonError, setReasonError] = useState(false);
  const queryClient = useQueryClient();
  const toast = useToast();

  const keywordFeedbackMutation = useMutation<any, Error, FeedbackData>(
    (feedbackData) => submitKeywordFeedback(targetId, feedbackData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['keyword', targetId]);
        toast({
          title: '키워드 피드백이 제출되었습니다',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        handleClose();
      },
      onError: (error: Error) => {
        toast({
          title: '키워드 피드백 제출 실패',
          description: error.message || '다시 시도해주세요',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const exampleFeedbackMutation = useMutation<any, Error, FeedbackData>(
    (feedbackData) => submitExampleFeedback(targetId, feedbackData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['example', targetId]);
        toast({
          title: '예시 피드백이 제출되었습니다',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        handleClose();
      },
      onError: (error: Error) => {
        toast({
          title: '예시 피드백 제출 실패',
          description: error.message || '다시 시도해주세요',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );  

  const handleSubmit = () => {
    if (feedbackType === 'bad' && !reason) {
      setReasonError(true);
      return;
    }

    const feedbackData: FeedbackData = {
      feedback_type: feedbackType,
      content: content || undefined,
    };

    if (feedbackType === 'bad') {
      feedbackData.reason = reason;
      if (reason === 'other') {
        feedbackData.other_reason = otherReason;
      }
    }

    if (targetType === 'keyword') {
      keywordFeedbackMutation.mutate(feedbackData);
    } else if (targetType === 'example') {
      exampleFeedbackMutation.mutate(feedbackData);
    }
  };

  const handleClose = () => {
    onClose();
    setContent('');
    setReason('');
    setOtherReason('');
    setReasonError(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="md" mx={{ base: 4, md: 0 }}>
        <ModalHeader px={6} py={6} borderTopRadius="md">
          <Text fontSize="xl" fontWeight="semibold">
            Feedback
          </Text>
        </ModalHeader>
        <ModalBody px={6} py={1}>
          <VStack spacing={feedbackType === 'bad' ? 4 : 6} align="stretch">
            {feedbackType === 'bad' && (
              <FormControl isInvalid={reasonError}>
                <FormLabel fontSize="sm" fontWeight="bold" mb={2}>
                 개선이 필요한 사항
                </FormLabel>
                <RadioGroup
                  onChange={(value) => {
                    setReason(value);
                    setReasonError(false);
                  }}
                  value={reason}
                >
                  <VStack align="start" spacing={2}>
                    {REASON_CHOICES.map((choice) => (
                      <Radio key={choice.value} value={choice.value} size="sm">
                        <Text fontSize="sm">{choice.label}</Text>
                      </Radio>
                    ))}
                  </VStack>
                </RadioGroup>
                {reason === 'other' && (
                  <Input
                    mt={2}
                    placeholder="기타 이유를 입력해주세요"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    size="sm"
                    fontSize="sm"
                  />
                )}
                {reasonError && (
                  <FormErrorMessage fontSize="xs">항목을 선택해주세요.</FormErrorMessage>
                )}
              </FormControl>
            )}
            <FormControl>
              <FormLabel fontSize="sm" fontWeight="bold" mb={2}>
              자세한 의견을 작성해 주세요:<Text as="span" fontSize="xs" color="gray.500" ml={1}>(선택 사항)</Text>
              </FormLabel>
              <Textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="해당 키워드에 대한 피드백을 작성해 주세요."
                size="sm"
                fontSize="sm"
                borderColor="gray.200"
                _hover={{ borderColor: "gray.300" }}
                _focus={{
                  borderColor: "red.300",
                  boxShadow: "0 0 0 1px var(--chakra-colors-red-300)",
                }}
                sx={{
                  '&:focus': {
                    borderWidth: '0.1px',
                  }
                }}
              />
            <Text fontSize="xs" color="gray.500" mt={2}>
            *이 피드백을 제출하면 현재 키워드가 Wordjourney로 전송되며 향후 서비스 개선을 위해 귀하의 의견과 함께 안전하게 저장됩니다. 
            피드백 제출 시, 당사는 개인정보 보호를 최우선으로 하며, 수집된 정보는 오직 서비스 품질 향상을 위해서만 사용됩니다. 
                <Link to="/privacy-policy">
                <Text as="span" textDecoration="underline" cursor="pointer" ml={1}>
                    개인정보처리방침
                </Text>
                </Link>
            </Text>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter px={6} py={4} borderBottomRadius="md">
          <Button 
            variant="outline"
            size="sm" 
            onClick={handleClose} 
            mr={3}
          >
            취소
          </Button>
          <Button
            bg="blue.300"
            color="white"
            variant="outline"
            size="sm" 
            onClick={handleSubmit}
            isLoading={keywordFeedbackMutation.isLoading || exampleFeedbackMutation.isLoading}
          >
            제출
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;